import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CartItem } from "../utils/types";
import { formatNumber } from "../utils/utils";
import { useFormik } from "formik";

const CheckOut = () => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [disable, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    const storedCartItems: CartItem[] = JSON.parse(
      localStorage.getItem("cartItems") || "[]"
    );
    setCartItems(storedCartItems);
  }, []);

  useEffect(() => {
    // Calculate total price whenever quantities change
    const total = cartItems.reduce((sum, item) => {
      const quantity = quantities[item.slug] || 1; // Default to 1 if not set
      return sum + item.price * quantity;
    }, 0);
    setTotalPrice(total);


    formik.setFieldValue(
      "product",
      cartItems.map((item) => ({
        image: item.imageSrc,
        title: item.title,
        quantity: quantities[item.slug] || 1,
        price: item.price,
        totalPrice: quantities[item.slug] * item.price
      }))
    );

  }, [quantities, cartItems]);

  const handleQualityChange =
    (slug: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value); // Convert the input value to a number
      if (value !== 0) {
        setQuantities((prevQuantities) => ({
          ...prevQuantities,
          [slug]: value,
        }));
      }
    };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      address: "",
      phoneNumber: "",
      product: []
    },
    onSubmit: (value) => {
      console.log(value);
    },
  });
  return (
    <>
      <Container sx={{ overflow: "hidden", mt: "80px" }}>
        <Box sx={{ width: "100%" }}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid
              spacing={3}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                flexDirection: { xs: "column", lg: "row" },
                mb: "20px",
              }}
            >
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  display: "block",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  width: { lg: "70%", xs: "100%" },
                  minHeight: "300px",
                  maxHeight: "300px",
                  background: "#fff",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center",
                    mt: "20px",
                  }}
                >
                  Personal information
                </Typography>
                <Box sx={{ px: "20px", mt: "20px" }}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Name"
                    type="text"
                    id="name"
                    sx={{ mb: "15px" }}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    size="small"
                    fullWidth
                    label="Email"
                    id="email"
                    type="email"
                    sx={{ mb: "15px" }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <TextField
                    size="small"
                    fullWidth
                    label="Address"
                    type="text"
                    id="address"
                    sx={{ mb: "15px" }}
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address &&
                      Boolean(formik.errors.address)
                    }
                    helperText={
                      formik.touched.address && formik.errors.address
                    }
                  />
                  <TextField
                    size="small"
                    fullWidth
                    label="Phone number"
                    type="tel"
                    id="phoneNumber"
                    sx={{ mb: "15px" }}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  py: { xs: "30px", lg: "20px" },
                  ml: { xs: "20px", lg: "70px" },
                  width: "100%",
                  background: "#fff",
                  minHeight: "250px",
                  px: "40px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {cartItems.length > 0 ? (
                  cartItems.map((data: any, index: number) => (
                    <Box
                      key={index}
                      sx={{
                        mb: "5px",
                        pb: "5px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexGrow: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "45px",
                          height: "45px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={data?.imageSrc}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          ml: "10px",
                          minWidth: "70%",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "#5D5D5E",
                            fontSize: "14px",
                          }}
                        >
                          {data.title}
                        </Typography>
                        <TextField
                          value={quantities[data.slug] || 1}
                          size="small"
                          sx={{ width: "15%" }}
                          type="number"
                          onChange={handleQualityChange(data.slug)}
                        />
                      </Box>

                      <Box
                        sx={{
                          cursor: "pointer",
                          width: "30px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "#5D5D5E",
                            textAlign: "justify",
                            fontSize: "14px",
                          }}
                        >
                          {formatNumber(
                            data.price * (quantities[data.slug] || 1)
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Cart is empty</Typography>
                  </Box>
                )}
                <Box sx={{ gap: 5 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexGrow: 1,
                      my: "20px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      Total:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      {formatNumber(totalPrice)}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Checkout
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default CheckOut;
