import React, { FC } from "react";
import { Colors, TextFormatting } from "../../utils/utils";
import { Box, Typography, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { TuckItems } from "../../utils/CardData";
import ButtonComp from "../Button/ButtonComp";
import { useCategory } from "../../utils/Constants";
import { ICategory } from "../../utils/types";

interface AllergenProps {
  showAllergen?: boolean;
  handleAllergen: () => void;
}

const Allergen: FC<AllergenProps> = ({ showAllergen, handleAllergen }) => {
  const color = Colors();

  const navigate = useNavigate();

  const handleCategory = (catName: string) => {
    const name = TextFormatting(catName);

    navigate(`/menu?category=${name}`);
  };

  const category = useCategory();

  return (
    <>
      {category
        ? category.map((data: ICategory) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "column", xs: "row" },
                alignItems: "center",
                transition: "transform 0.5s ease",
                textAlign: "center",
                cursor: "pointer",
                "&:hover": {
                  "& .image": {
                    transform: "translateY(-15px)",
                    transition: "transform 0.6s ease",
                  },
                },
              }}
              onClick={() => handleCategory(data.attributes.title)}
            >
              <Box
                sx={{
                  width: { lg: "100px", xs: "60px" },
                  height: { lg: "100px", xs: "60px" },
                }}
              >
                <img
                  className="image"
                  src={`${data.attributes.image.data[0].attributes.url}`}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Typography
                variant="h6"
                sx={{
                  color: color.dark,
                  fontFamily: "Roboto",
                  fontWeight: { lg: 900 },
                  mt: "5px",
                  fontSize: { lg: "1rem", xs: "" },
                  ml: { lg: "0px", xs: "10px" },
                }}
              >
                {data.attributes.title}
              </Typography>
            </Box>
          ))
        : TuckItems.map((data: any) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "column", xs: "row" },
                alignItems: "center",
                transition: "transform 0.5s ease",
                textAlign: "center",
                cursor: "pointer",
                "&:hover": {
                  "& .image": {
                    transform: "translateY(-15px)",
                    transition: "transform 0.6s ease",
                  },
                },
              }}
              onClick={() => handleCategory(data.category)}
            >
              <Box
                sx={{
                  width: { lg: "100px", xs: "60px" },
                  height: { lg: "100px", xs: "60px" },
                }}
              >
                <img
                  className="image"
                  src={data.image}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Typography
                variant="h6"
                sx={{
                  color: color.dark,
                  fontFamily: "Roboto",
                  fontWeight: { lg: 900 },
                  mt: "5px",
                  fontSize: { lg: "1rem", xs: "" },
                  ml: { lg: "0px", xs: "10px" },
                }}
              >
                {data.category}
              </Typography>
            </Box>
          ))}
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          gap: 4,
          width: { xs: "50%", lg: "auto" },
        }}
      >
        <ButtonComp
          variant="outlined"
          text="View all products"
          href="/menu"
          fullWidth
        />
        {/* <ButtonComp
          variant="contained"
          text="allergens & Nutritions"
          href="/nutrition"
          fullWidth
        /> */}
      </Box>
    </>
  );
};

export default Allergen;
