import { Stack, Box } from "@mui/material";
import { FC } from "react";
import MenuItems from "./MenuItems";
import { GET_MIXMATCH_PRODUCT, GET_PRODUCT_CATEGORY } from "../../utils/types";
import { useQuery } from "@apollo/client";

interface MenuCardProps {
  categoryName: string;
  valueId: string;
  category: any;
  pathName?: string;
}

const MenuCard: FC<MenuCardProps> = ({
  categoryName,
  valueId,
  category,
  pathName,
}) => {
  const { data: categoryOne } = useQuery(GET_PRODUCT_CATEGORY, {
    variables: { title: categoryName },
  });
  const { data: categoryTwo } = useQuery(GET_MIXMATCH_PRODUCT, {
    variables: { title: categoryName },
  });

  const categoryData =
    pathName === "mix-and-match"
      ? categoryTwo?.categories.data
      : categoryOne?.categories.data;

  return (
    <>
      <Stack>
        <Box sx={{ minHeight: "200px" }}>
          {categoryName === "ALL" || valueId === "0"
            ? category &&
              category.map((data: any) => (
                <MenuItems
                  key={data.id}
                  productItems={data}
                  pathName={pathName}
                />
              ))
            : categoryData &&
              categoryData.map((data: any) => (
                <MenuItems productItems={data} pathName={pathName} />
              ))}
        </Box>
      </Stack>
    </>
  );
};

export default MenuCard;
