import {
  AppBar,
  Box,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { TopNav } from "../TopNav";
import { animateScroll as scroll } from "react-scroll";
import DehazeOutlinedIcon from "@mui/icons-material/DehazeOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Footer from "../Footer/footer";
import { Link } from "react-router-dom";
import Allergen from "../Cards/Allergen";
import { useAppIdentity } from "../../utils/Constants";

interface LayoutProps {
  children: JSX.Element;
  title: String;
  menuColor?: boolean;
  noFooter?: boolean;
}

const Layout: FC<LayoutProps> = ({ title, children, menuColor, noFooter }) => {
  const theme = useTheme();
  const color = theme.palette.primary;
  const [isToggle, setIsToggle] = useState(false);

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const ToggleMobileMenu = () => {
    setIsToggle(!isToggle);
  };
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 15) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  // let logoColor;

  // if (isToggle) {
  //   logoColor = color.dark;
  // }
  // if (menuColor) {
  //   logoColor = color.dark;
  // }

  const [showAllergen, setShowAllergen] = useState(false);

  const handleAllergen = () => {
    setShowAllergen(!showAllergen);
  };

  const AppIdentity = useAppIdentity();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      {/* AppBar */}
      <Box
        sx={{
          position: "relative",
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        id="home"
      >
        {/* Add CSS animation keyframes */}
        <style>
          {`
          @keyframes scaleAnimation {
            from {
              transform: scale(1);
            }
            to {
              transform: scale(1.1);
            }
          }
        `}
        </style>
        {scrolling ? (
          <Stack
            sx={{
              position: "fixed",
              background: color.main,
              height: "45px",
              width: "45px",
              borderRadius: "50%",
              flexWrap: "wrap",
              alignContent: "center",
              justifyContent: "center",
              mb: "15px",
              color: "#fff",
              zIndex: 99,
              bottom: { xs: "5%", lg: "10%" },
              right: "2%",
              cursor: "pointer",
            }}
            className="icon"
            style={{
              animation: "scaleAnimation 1s infinite alternate", // Apply the animation
            }}
            onClick={scrollToTop}
          >
            <KeyboardArrowUpIcon />
          </Stack>
        ) : null}
        <Box sx={{}}>
          <AppBar
            component="nav"
            position="fixed"
            elevation={0}
            sx={{
              background: scrolling || isToggle || menuColor ? "#fff" : "none",

              boxShadow: scrolling ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "",
              transition: "background 0.3s",
            }}
          >
            <Toolbar sx={{ mx: { xs: 0, md: "50px" } }}>
              <Link
                to={"/"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                  flexGrow: 1,
                  textDecoration: "none",
                  alignContent: "center",
                }}
                onClick={showAllergen ? () => handleAllergen() : undefined}
              >
                {/* <Logo scrolling={scrolling} colors={logoColor} /> */}
                {/* <Box sx={{ width: "50px", height: "50px", overflow: "hidden" }}>
                  {AppIdentity?.attributes.logo.data.attributes.url && (
                    <img
                      src={`${AppIdentity?.attributes.logo.data.attributes.url}`}
                      alt={AppIdentity?.attributes.logo.data.attributes.url}
                      style={{ width: "100%", objectFit: "cover" }}
                    />
                  )}
                </Box> */}
                <Typography
                  variant="h5"
                  component="div"
                  fontWeight={900}
                  fontFamily="barlow"
                  sx={{
                    color:
                      scrolling || isToggle || menuColor ? color.dark : "#fff",
                    ml: "10px",
                  }}
                >
                  {AppIdentity ? AppIdentity?.attributes.identity : "Teettam Bakery"}
                </Typography>
              </Link>

              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <Box
                  onClick={ToggleMobileMenu}
                  color={
                    scrolling || isToggle || menuColor ? color.main : "#fff"
                  }
                  sx={{ cursor: "pointer" }}
                >
                  {isToggle ? (
                    <CloseOutlinedIcon sx={{ fontWeight: "bold" }} />
                  ) : (
                    <DehazeOutlinedIcon sx={{ fontWeight: "bold" }} />
                  )}
                </Box>
                {isToggle && (
                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                      position: "absolute",
                      left: "0%",
                      mt: "11px",
                      zIndex: 99,
                      height: "100vh",
                      overflowY: "scroll",
                    }}
                  >
                    <Paper
                      sx={{
                        maxWidth: "100%",
                        width: width,
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        pb: "30px",
                      }}
                    >
                      <TopNav
                        scrolling={scrolling}
                        isToggle={isToggle}
                        clickFunction={() => handleAllergen()}
                        showAllergin={showAllergen}
                        toggle={() => ToggleMobileMenu()}
                      />
                    </Paper>
                  </Box>
                )}
              </Box>

              {isMobile ? null : (
                <Box>
                  <TopNav
                    scrolling={scrolling}
                    isToggle={isToggle}
                    menuColor={menuColor}
                    clickFunction={() => handleAllergen()}
                    showAllergin={showAllergen}
                    toggle={() => ToggleMobileMenu()}
                  />
                </Box>
              )}
            </Toolbar>

            {showAllergen && !isMobile && (
              <Box
                sx={{
                  minHeight: "100vh",
                  position: "fixed",
                  mt: "64px",
                  zIndex: 999,
                  width: "100%",
                  backdropFilter: "blur(8px)",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  transition: "height 0.5s ease",
                  overflow: "hidden",
                  height: showAllergen ? "100vh" : "0",
                }}
                onClick={() => handleAllergen()}
              >
                <Box
                  sx={{
                    display: "flex",
                    minHeight: "220px",
                    background: "#fff",
                    borderBottomLeftRadius: "90px",
                    borderBottomRightRadius: "90px",
                    transition: "transform 0.5s ease",
                    transform: showAllergen
                      ? "translateY(0)"
                      : "translateY(100%)",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: "200px",
                  }}
                >
                  <Allergen
                    handleAllergen={handleAllergen}
                    showAllergen={showAllergen}
                  />
                </Box>
              </Box>
            )}
          </AppBar>
        </Box>

        {/* AppBar */}

        <Box sx={{}}>{children}</Box>

        {/* Footer comp */}
        {!noFooter && (
          <Box sx={{ marginTop: "auto" }}>
            <Footer identity={AppIdentity ? (AppIdentity?.attributes.identity as string ): "Teettam Bakery"} />
          </Box>
        )}
        {/* Footer comp */}
      </Box>
    </>
  );
};

export default Layout;
