import { Stack, Box, Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import useStyles from "../utils/useStyles";
import { Colors } from "../utils/utils";

const CookiePolicy = () => {
  const color = Colors();
  const style = useStyles();
  return (
    <>
      <Stack sx={{ overflow: "hidden", background: "#fff" }}>
        <Box sx={{ mt: "64px" }}>
          <Container maxWidth="md" sx={{}}>
            <Box>
              <Typography
                variant="h2"
                component="div"
                fontWeight={900}
                sx={{
                  color: color.dark,
                  fontFamily: "Roboto",
                  fontSize: { xs: "2rem", lg: "2.8rem" },
                  mb: "30px",
                }}
              >
                Cookie Policy
              </Typography>
            </Box>
            <Box>
              <Typography sx={style.text}>
                {" "}
                Effective Date: 1st July, 2024
              </Typography>
              <Typography sx={style.textHead}>Introduction </Typography>
              <Typography sx={style.text}>
                Teettam ("we", "our", "us") is committed to protecting your
                privacy and ensuring that your personal information is handled
                in a safe and responsible manner. This Cookie Policy explains
                what cookies are, how we use them, and your choices regarding
                cookies.
              </Typography>
              <Typography sx={style.textHead}>What are cookies? </Typography>
              <Typography sx={style.text}>
                Cookies are small text files that are stored on your device
                (computer, tablet, smartphone) when you visit websites. They are
                used to make websites work more efficiently, as well as to
                provide information to the owners of the site.
              </Typography>
              <Typography sx={style.textHead}>How We Use Cookies </Typography>
              <Typography sx={style.text}>
                We use cookies to improve your browsing experience, analyze site
                traffic, and understand where our visitors are coming from.
                Specifically, we use cookies to:
              </Typography>
              <Typography sx={style.text}>
                <ul>
                  <li>Ensure the website functions correctly.</li>
                  <li>Remember your preferences and settings.</li>
                  <li>Allow you to log in and access your account.</li>
                  <li>Monitor and analyze website performance and usage.</li>
                  <li>Provide personalized content and advertisements.</li>
                </ul>
              </Typography>
              <Typography sx={style.textHead}>
                Types of Cookies We Use
              </Typography>
              <Typography sx={style.text}>
                <ol>
                  <li>
                    Necessary Cookies: These cookies are essential for the
                    website to function properly. They enable basic features
                    like page navigation and access to secure areas of the site.
                  </li>
                  <li>
                    Preference Cookies: These cookies allow the website to
                    remember choices you have made in the past, like your
                    language preference or login details.
                  </li>
                  <li>
                    Analytics Cookies: These cookies help us understand how
                    visitors interact with our website by collecting and
                    reporting information anonymously..
                  </li>
                  <li>
                    Marketing Cookies: These cookies are used to track visitors
                    across websites. The intention is to display ads that are
                    relevant and engaging for the individual user.
                  </li>
                </ol>
              </Typography>
              <Typography sx={style.textHead}>
                Your Choices Regarding Cookies{" "}
              </Typography>
              <Typography sx={style.text}>
                You have the right to accept or reject cookies. Most web
                browsers automatically accept cookies, but you can usually
                modify your browser setting to decline cookies if you prefer.
                Please note that disabling cookies may affect the functionality
                and features of our website.
              </Typography>
              <Typography sx={style.textHead}>
                Changes to This Cookie Policy
              </Typography>
              <Typography sx={style.text}>
                We may update our Cookie Policy from time to time to reflect
                changes in our practices and services. We encourage you to
                review this page periodically for the latest information on our
                cookie practices.
              </Typography>
              <Typography variant="h6" sx={style.textHead}>
                Contact Us
              </Typography>
              <Typography sx={style.text}>
                If you have any questions or concerns about these Cookie Policy,
                please contact us at <Link to="/contact-us">here</Link>
              </Typography>
            </Box>
          </Container>
        </Box>
      </Stack>
    </>
  );
};

export default CookiePolicy;
