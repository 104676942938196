import { Stack, Box, Typography, useTheme, TextField } from "@mui/material";
import React, { FC } from "react";
import ButtonComp from "../Button/ButtonComp";

interface NewsLetterProps {
  title: string;
  subTitle: string;
  policy?: string | JSX.Element;
}

const Newsletter: FC<NewsLetterProps> = ({ title, subTitle, policy }) => {
  const theme = useTheme();
  const color = theme.palette.primary;
  return (
    <>
      <Stack id="" pt="30px">
        <Box
          sx={{
            minHeight: "450px",
            width: "100%",
            maxWidth: "100%",
            background: "#FDB71A",
            textAlign: "center",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            py: "50px",
          }}
        >
          <Box sx={{ mx: "60px" }}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Barlow Condensed, sans-serif",
                fontWeight: 800,
                fontSize: { xs: "2.75rem", lg: "2.75rem" },
                lineHeight: 1,
                color: color.dark,
                mb: 3.6,
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box sx={{ mx: "20px" }}>
            <Typography
              sx={{
                fontFamily: "Roboto, sans-serif",
                width: { xs: "auto", lg: "750px" },
                lineHeight: 1.6,
                mb: 2,
                fontWeight: 600,
              }}
            >
              {subTitle}
            </Typography>
            {policy && (
              <Typography
                sx={{
                  fontFamily: "Roboto, sans-serif",
                  lineHeight: 1.6,
                  fontWeight: 600,
                  mb: 2,
                }}
              >
                {policy}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              width: { lg: "40%", xs: "90%" },
              gap: { xs: 3, lg: 1 },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ flex: 1, width: { xs: "100%" } }}>
              <TextField
                placeholder="Enter email address"
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                  background: "#fff",
                  borderRadius: "5px",
                }}
              />
            </Box>
            <Box sx={{ width: { xs: "100%", lg: "auto" } }}>
              <ButtonComp
                variant="contained"
                background={color.dark}
                text="Sign Up"
                width="100%"
                fullWidth
              />
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default Newsletter;
