import { Box, Typography } from "@mui/material";
import React, { FC } from "react";

interface HeroProps {
  image?: string;
  backImage?: string;
  title: string;
  subtitle?: string;
  button?: JSX.Element;
  button2?: JSX.Element;
  titleMainColor: string;
  titleSecColor?: string;
  backgroundColor: string;
  heroHeight?: string;
  heroHeightMob?: string;
  fixed?: boolean;
  single?: boolean;
}

const Hero: FC<HeroProps> = ({
  image,
  title,
  subtitle,
  button,
  button2,
  titleMainColor,
  titleSecColor,
  backgroundColor,
  heroHeight,
  heroHeightMob,
  fixed,
  single,
  backImage,
}) => {
  return (
    <>
      <Box
        sx={{
          minHeight: {
            xs: heroHeightMob ? heroHeightMob : "105vh",
            lg: heroHeight ? heroHeight : "650px",
          },
          borderBottomLeftRadius: "90px",
          borderBottomRightRadius: "90px",
          overflow: "hidden",
          position: "relative",
          background: backgroundColor,
          display: "flex",
          flexDirection: { lg: "row", xs: single ? "row" : "column" },
          flexWrap: "wrap",
          alignContent: { lg: "center", xs: "center" },
          justifyContent: "space-between",
          px: { lg: "120px", xs: "0px" },
        }}
      >
        {single ? (
          <>
            <img
              src={backImage}
              alt=""
              style={{
                objectFit: "cover",
                width: "100%", // Ensure the image takes the full width of the grid
                height: "100%",
              }}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignSelf: "center",
                zIndex: 999,
              }}
            >
              <Typography
                variant="h2"
                component="div"
                fontWeight={900}
                sx={{
                  color: {
                    xs: titleSecColor ? titleSecColor : titleMainColor,
                    lg: titleMainColor,
                  },
                  fontFamily: "Roboto",
                  fontSize: { xs: "3.5rem", lg: "7rem" },
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Typography>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              mt: { lg: "45px", xs: "95px" },
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "center",
              textAlign: { lg: "left", xs: "center" },
              width: { lg: subtitle ? "40%" : "60%", xs: "100%" },
              alignItems: { xs: "center", lg: "start" },
            }}
          >
            <Typography
              variant="h2"
              component="div"
              fontWeight={900}
              sx={{
                color: {
                  xs: titleSecColor ? titleSecColor : titleMainColor,
                  lg: titleMainColor,
                },
                fontFamily: "Roboto",
                fontSize: { xs: "3.5rem", lg: "5rem" },
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                my: "25px",
                color: "#fff",
                fontWeight: 500,
                fontFamily: "Barlow",
                width: { lg: "100%", xs: "90%" },
                fontSize: { xs: "1rem", lg: "1.0rem" },
              }}
            >
              {subtitle}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: "center", lg: "space-between" },
                width: { lg: "100%", xs: "90%" },
                alignSelf: { xs: "center", lg: "auto" },
                gap: 3,
              }}
            >
              {button} {button2}
            </Box>
          </Box>
        )}

        {fixed ? (
          <Box
            sx={{
              maxHeight: { xs: "470px", lg: "350px" },
              maxWidth: { xs: "420px", lg: "600px" },
              position: { lg: "absolute" },
              bottom: { lg: 0, xs: 0 },
              right: { lg: "5%", xs: "" },
              borderTopLeftRadius: "60px",
              borderTopRightRadius: "60px",
              overflow: "hidden",
              display: "flex",
              alignContent: "center",
              alignSelf: "center",
            }}
          >
            <img
              src={image}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: { lg: subtitle ? "50%" : "40%", xs: "100%" },
              alignSelf: "center",
              mb: { xs: "0px", lg: "0px" },
            }}
          >
            <img
              src={image}
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Hero;
