import { Grid, Box, Typography, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

interface YouMayLikeCardProps {
  index: number;
  title: string;
  description: string;
  image: string;
  slug: string;
}
const YouMayLikeCard: FC<YouMayLikeCardProps> = ({
  index,
  title,
  description,
  image,
  slug,
}) => {
  const theme = useTheme();

  const color = theme.palette.primary;

  const navigate = useNavigate();

  const handleCategory = (catName: string) => {
    navigate(`/menu/product/${catName}`);
  };
  return (
    <>
      <Grid
        xs={12}
        sm={6}
        lg={4}
        sx={{}}
        xl={4}
        key={index}
        onClick={() => handleCategory(slug)}
      >
        <Box
          sx={{
            width: "420px",
            minHeight: "240px",
            background: "#fff",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "center",
            cursor: "pointer",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: -40,
              right: 0,
              width: "90px",
              height: "90px",
              overflow: "hidden",
              borderRadius: "50%",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <img
              src={image}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 900,
              fontSize: "2.3rem",
              color: color.dark,
              mx: "10px",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              mt: "20px",
              fontFamily: "Barlow",
              fontWeight: 500,
              color: "#5D5D5E",
              mx: "10px",
            }}
          >
            {description?.length > 175
              ? `${description.slice(0, 175)}....`
              : description}
          </Typography>
          <Typography
            sx={{
              mt: "20px",
              fontWeight: 700,
              fontFamily: "Roboto",
              mx: "10px",
            }}
          >
            VIEW PRODUCT
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default YouMayLikeCard;
