import { useSearchParams } from "react-router-dom";

const useQueryParams = (
  key: string
): {
  setQueryParam: (val?: string | null) => void;
  getQueryParam: string | null;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryParam = (val?: string | null) => {
    if (val) {
      searchParams.set(key, val);
    } else searchParams.delete(key);

    setSearchParams(searchParams);
  };

  const getQueryParam = searchParams.get(key);

  return { setQueryParam, getQueryParam };
};

export default useQueryParams;
