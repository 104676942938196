import { useTheme } from "@mui/material";

export interface CategoryProps {
  id: string;
  category: string;
  image: string;
  handle: string;
  children: [
    {
      image: string;
      title: string;
    }
  ];
}

export const formatDescription = (description: string) => {
  if (description) {
    const sentences = description.split("/").filter(Boolean);
    return sentences;
  }
};

export const getRandomItems = (array: any[], count: number): any[] => {
  if (!Array.isArray(array)) {
    return [];
  }
  const arrayCopy = [...array];
  const shuffled = arrayCopy.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

export const Colors = () => {
  const theme = useTheme();

  return theme.palette.primary;
};

export const TextFormatting = (text: string) => {
  const Formatted = text
    .toLowerCase()
    .replace(/\s*&\s*/g, " ") // Replace ' & ' with a space
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^\w-]/g, "");

  return Formatted;
};

export function formatTitle(title: string): string {
  return title
    .split("-") // Split the string by hyphens
    .map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter of each word
    )
    .join(" "); // Join the words with spaces
}

export const formatNumber = (number: number): string => {
  return number.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
    currencyDisplay: "symbol",
  }); // Converts number to a string with currency symbol and thousands separator
};
