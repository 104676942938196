import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";

interface MixMatchIngredientsProps {
  data: any;
  handleValue: (price: number, name: string, total: number) => void;
}

const MixMatchIngredients: FC<MixMatchIngredientsProps> = ({
  data,
  handleValue,
}) => {
  const [flour, setFlour] = useState("");
  const sliderRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [previousSliderPrice, setPreviousSliderPrice] = useState(0);
  const [previousSelectPrice, setPreviousSelectPrice] = useState(0);
  const [name, setName] = useState("");
  const [sliderValue, setSliderValue] = useState(0);

  const handleChange = async (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string;

    if (selectedValue) {
      setPreviousSelectPrice(parseFloat(selectedValue));
    }

    const selectedOption = data.mixmatch.find(
      (item: any) => item.price === selectedValue
    );

    if (selectedOption) {
      setName(selectedOption.name);
      setSliderValue(parseInt(selectedValue) + 1);
    }

    setTotalPrice(parseInt(selectedValue));

    setFlour(selectedValue);
  };

  const handleSliderChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (typeof newValue === "number") {
      setSliderValue(newValue);

      if (sliderRef.current) {
        const preferredPrice = sliderRef.current.getAttribute(
          "data-preferred-price"
        );
        const preferredName = sliderRef.current.getAttribute(
          "data-preferred-name"
        );

        if (preferredPrice && preferredName) {
          const price = parseFloat(preferredPrice);
          setTotalPrice((prevTotal) => prevTotal - previousSliderPrice + price);
          setPreviousSliderPrice(price);
          setName(preferredName);
        }
      }
    }
  };

  useEffect(() => {
    const total = previousSliderPrice + previousSelectPrice;

    handleValue(sliderValue, name, total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousSliderPrice, previousSelectPrice, totalPrice, sliderValue]);

  return (
    <>
      {data.input === "slider" && data.mixmatch.length > 0 && (
        <Slider
          size="small"
          defaultValue={sliderValue}
          value={sliderValue}
          data-preferred-price={parseFloat(data.mixmatch[0].price)}
          data-preferred-name={data.name}
          aria-label="Small"
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          ref={sliderRef}
        />
      )}

      {data.input === "options" && (
        <FormControl fullWidth>
          <InputLabel
            id="demo-simple-select-label"
            sx={{ textTransform: "capitalize" }}
          >
            {data && data.name}
          </InputLabel>
          <Select
            label={data.name}
            id="demo-simple-select"
            value={flour}
            ref={optionRef}
            onChange={handleChange}
          >
            {data &&
              data.mixmatch.map((data: any) => (
                <MenuItem
                  key={data.name}
                  value={data.price}
                  data-preferred-price={data.price}
                  data-preferred-name={data.name}
                >
                  {data && data.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default MixMatchIngredients;
