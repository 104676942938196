import {
  Box,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Tab,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { formatNumber, getRandomItems } from "../utils/utils";
import YouMayLikeCard from "../Components/Cards/YouMayLikeCard";
import ButtonComp from "../Components/Button/ButtonComp";
import { useProduct } from "../utils/Constants";
import { useQuery } from "@apollo/client";
import { CartItem, GET_PRODUCT_CATEGORY, ICategory } from "../utils/types";
import { useRef, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MixMatchIngredients from "../Components/Form/MixMatch";

interface IngredientData {
  ingredient: string;
  quantity: number;
  price: number;
}

const Product = () => {
  const theme = useTheme();

  const color = theme.palette.primary;

  const location = useLocation();

  const ProductName = location.pathname.split("/")[3];

  const pathName = location.pathname.split("/")[1];

  const productQuery = useProduct(ProductName);

  const [ingredient, setIngredients] = useState<IngredientData[]>([]);

  const { data } = useQuery(GET_PRODUCT_CATEGORY, {
    variables: {
      title: productQuery?.attributes?.category?.data?.attributes?.title,
    },
  });

  const categoryData = data?.categories?.data as ICategory[];

  const youMayLike =
    categoryData &&
    categoryData[0] &&
    getRandomItems(categoryData[0].attributes.products.data, 3);

  const [imagePath, setImagePath] = useState("");

  const changeImages = (imagePath: string, index: number) => {
    setImagePath(imagePath);
  };

  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const imageRef = useRef<HTMLImageElement>(null);

  const handleAddToCart = (title: string, price: number) => {
    if (imageRef.current) {
      const imageSrc = imageRef.current.src;
      const slug = ProductName;
      const ingredients = ingredient
      const newItem: CartItem = { imageSrc, title, price, pathName, slug, ingredients };

      // Retrieve the existing cart items from local storage
      const cartItems: CartItem[] = JSON.parse(
        localStorage.getItem("cartItems") || "[]"
      );

      // Append the new item to the cart items array
      cartItems.push(newItem);

      // Store the updated cart items array in local storage
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }
  };

  const [mixMatchPrice, setMixMatchPrice] = useState(0);

  const handleValuePrice = (quantity: number, name: string, price: number) => {
    // Create a new object with the passed values
    const newData: IngredientData = {
      ingredient: name,
      quantity: quantity,
      price: price,
    };

    // Update the array by removing duplicates and adding the new data
    setIngredients((prevIngredients) => {
      // Filter out the old entry if it exists
      const filteredIngredients = prevIngredients.filter(
        (ingredient) => ingredient.ingredient !== name
      );

      // Add the new entry
      const updatedIngredients = [...filteredIngredients, newData];

      // Filter out entries with price and quality equal to 0
      const validIngredients = updatedIngredients.filter(
        (ingredient) => ingredient.price !== 0 && ingredient.quantity !== 0
      );

      // Sum up the total price
      const totalPrice = validIngredients.reduce(
        (acc, ingredient) => acc + ingredient.price,
        0
      );

      setIngredients(validIngredients);
      setMixMatchPrice(totalPrice);

      return validIngredients;
    });
  };

  console.log(ingredient)
  return (
    <>
      <Stack sx={{ background: "#fff", overflow: "hidden" }}>
        <Box
          sx={{
            mt: { lg: "50px" },
            background: "#F7F7F7",
            px: { xs: "20px", lg: "120px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            mb: "30px",
          }}
        >
          <Grid
            spacing={3}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              flexDirection: { xs: "column", lg: "row" },
              my: "90px",
            }}
          >
            <Grid
              item
              xs={12}
              lg={5}
              sx={{
                display: "block",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                width: { lg: "70%", xs: "100%" },
              }}
            >
              <Stack sx={{ overflow: "hidden" }}>
                <Box
                  sx={{
                    height: "430px",
                    position: "relative",
                  }}
                >
                  {imagePath ? (
                    <img
                      ref={imageRef}
                      src={imagePath}
                      alt={
                        productQuery?.attributes.image.data[0].attributes
                          .alternativeText
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    productQuery?.attributes?.image?.data?.[0]?.attributes
                      ?.url && (
                      <img
                        ref={imageRef}
                        src={
                          `${productQuery?.attributes.image.data[0].attributes.url}` ||
                          imagePath
                        }
                        alt={
                          productQuery?.attributes.image.data[0].attributes
                            .alternativeText
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )
                  )}
                </Box>
                <Box
                  sx={{
                    height: "90px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {productQuery &&
                    productQuery.attributes.image.data.map((data, index) => (
                      <Box
                        sx={{
                          width: "80px",
                          height: "80px",
                          background: "#fff",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => changeImages(data.attributes.url, index)}
                      >
                        <img
                          src={`${data.attributes.url}`}
                          alt={data.attributes.alternativeText}
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        />
                      </Box>
                    ))}
                </Box>
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              lg={7}
              sx={{
                py: { xs: "30px", lg: "0px" },
                ml: { xs: "20px", lg: "70px" },
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 800,
                  fontSize: { xs: "2.0rem", lg: "2.0rem" },
                  lineHeight: 1,
                  color: color.dark,
                }}
              >
                {productQuery?.attributes.title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 4,
                  mt: "20px",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: 800,
                    lineHeight: 1,
                    color: color.dark,
                  }}
                >
                  {pathName !== "mix-and-match"
                    ? formatNumber(productQuery?.attributes.price ?? 0)
                    : formatNumber(mixMatchPrice ?? 0)}
                </Typography>
              </Box>

              {pathName !== "menu" && (
                <Box sx={{ my: "20px" }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: "#5D5D5E",
                      textAlign: "justify",
                    }}
                  >
                    Ingredients
                  </Typography>

                  <Stack sx={{ width: "60%" }}>
                    {productQuery &&
                      productQuery?.attributes?.ingredients?.map(
                        (data: any, index: any) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              my: "15px",
                              justifyContent: "space-between",
                              gap: 4,
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  color: "#5D5D5E",
                                  textAlign: "justify",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.mixmatch.length > 0 && data.name}
                              </Typography>
                            </Box>
                            <Box sx={{ width: 300 }}>
                              {data.mixmatch.length > 0 && (
                                <MixMatchIngredients
                                  data={data}
                                  handleValue={handleValuePrice}
                                />
                              )}
                            </Box>
                          </Box>
                        )
                      )}
                  </Stack>
                </Box>
              )}

              <Box sx={{ width: "100%", mt: "20px", overflow: "hidden" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Description" value="1" />
                      <Tab label="Contents" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Box sx={{ mt: "20px" }}>
                      {productQuery?.attributes.desc &&
                        productQuery?.attributes.desc.map(
                          (paragraph: any, index: any) => (
                            <Typography
                              sx={{
                                textAlign: "justify",
                                fontFamily: "Roboto",
                                color: "#5D5D5E",
                                mb: "10px",
                                width: { lg: "80%" },
                              }}
                            >
                              {paragraph.children[0].text}
                            </Typography>
                          )
                        )}
                    </Box>
                  </TabPanel>
                  <TabPanel value="2">
                    <Box sx={{ gap: 1 }}>
                      {productQuery &&
                        productQuery.attributes.content &&
                        productQuery.attributes.content.map((data, index) => (
                          <ListItem key={index} component="div" disablePadding>
                            <ListItemButton>
                              <ListItemText primary={data} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                    </Box>
                  </TabPanel>
                  {/* <TabPanel value="3"> */}
                  {/* <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    > */}
                  {/* <Typography>More</Typography> */}
                  {/* <Box>
                        <TableContainer sx={{ border: "none" }}>
                          <Table
                            sx={{ width: 500 }}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>
                                  Typical Values
                                </StyledTableCell>
                                <StyledTableCell>Per 100g</StyledTableCell>
                                <StyledTableCell>
                                  Per Portion (119.5g)
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableContent />
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box> */}
                  {/* <Box
                        sx={{
                          mt: "20px",
                          textAlign: "center",
                          width: { lg: "70%", xs: "80%" },
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, color: "#5D5D5E" }}>
                          Allergens*:{" "}
                        </Typography>
                        <Typography
                          sx={{
                            mt: "20px",
                            fontWeight: 600,
                            color: "#5D5D5E",
                          }}
                        >
                          *specific allergen information by product recipe. More
                          detailed information can be found in our allergen
                          guide below.
                        </Typography>
                        <Box sx={{ mt: "20px", alignSelf: "center" }}>
                          <ButtonComp
                            icon={
                              <FileDownloadOutlinedIcon sx={{ ml: "10px" }} />
                            }
                            variant="contained"
                            background={color.dark}
                            text="Our Allergen Guide"
                            fullWidth
                          />
                        </Box>
                      </Box> */}
                  {/* </Box> */}
                  {/* </TabPanel> */}
                </TabContext>
              </Box>

              <Box sx={{}}>
                <ButtonComp
                  variant="contained"
                  text="Add to Cart"
                  onClick={() =>
                    handleAddToCart(
                      productQuery?.attributes.title as string,
                      pathName === "mix-and-match"
                        ? mixMatchPrice
                        : (productQuery?.attributes.price as number)
                    )
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* Second Part */}
        <Box
          sx={{
            py: "60px",
            // background: "red",
            mb: "20px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontWeight: 900, fontFamily: "Roboto", color: color.dark }}
          >
            MORE PRODUCTS
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 900,
              fontFamily: "Roboto",
              color: color.dark,
              mt: "20px",
              fontSize: { lg: "3.75rem", xs: "2.2rem" },
            }}
          >
            You may also like
          </Typography>
        </Box>
        <Box
          sx={{
            minHeight: { lg: "430px", xs: "100%" },
            background: "#F7F7F7",
            display: "flex",
            justifyContent: "center",
            px: "50px",
          }}
        >
          <Grid
            spacing={2}
            gap={10}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignContent: "center",
              py: { lg: "0PX", XS: "20PX" },
            }}
          >
            {youMayLike &&
              youMayLike.map((data, index) => (
                <YouMayLikeCard
                  slug={data.attributes.slug}
                  index={index}
                  title={data.attributes.title}
                  description={data.attributes.desc[0]?.children[0]?.text}
                  image={`${data.attributes.image.data[0]?.attributes.url}`}
                />
              ))}
          </Grid>
        </Box>
        {/* <Container maxWidth="lg" sx={{ py: "60px" }}>
          <Grid
            spacing={2}
            gap={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              aligncontent: "center",
              flexWrap: "wrap",
            }}
          >
            <TaskCard />
          </Grid>
        </Container> */}
      </Stack>
    </>
  );
};

export default Product;
