import { Typography, Box, Grid, useTheme } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface MenuItemsProps {
  productItems: any;
  pathName?: string;
}

const MenuItems: FC<MenuItemsProps> = ({ productItems, pathName }) => {
  const theme = useTheme();
  const color = theme.palette.primary;

  const navigate = useNavigate();

  const handleCategory = (catName: string) => {
    navigate(`/${pathName}/product/${catName}`);
  };

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        sx={{
          textAlign: "center",
          fontWeight: 900,
          color: color.dark,
          fontFamily: "Roboto",
          fontSize: "1.5rem",
          borderBottom: 1,
          borderColor: "#C1C1C1",
        }}
      >
        {productItems.attributes.title}
      </Typography>
      <Box sx={{ my: "50px" }}>
        {productItems && productItems.attributes.products.data.length > 0 ? (
          <Grid
            spacing={2}
            gap={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              alignContent: "center",
            }}
          >
            {productItems.attributes.products.data.map((data: any) => (
              <Grid
                item
                key={data.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flexWrap: "wrap",
                  textAlign: "center",
                  transition: "transform 0.5s ease",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() => handleCategory(data.attributes.slug)}
              >
                <Box
                  sx={{
                    width: { xs: "120px", lg: "180px" },
                    height: { xs: "120px", lg: "180px" },
                    borderRadius: "50%",
                    overflow: "hidden",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {data?.attributes?.image?.data?.[0]?.attributes?.url && (
                    <img
                      src={`${data?.attributes.image.data[0].attributes.url}`}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </Box>
                <Typography
                  variant="h6"
                  component="div"
                  fontWeight="bold"
                  sx={{
                    fontFamily: "Roboto",
                    color: color.dark,
                    mt: "15px",
                  }}
                >
                  {data?.attributes.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>No data</Typography>
        )}
      </Box>
    </>
  );
};

export default MenuItems;
