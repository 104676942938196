import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import PauseCircleFilledOutlinedIcon from "@mui/icons-material/PauseCircleFilledOutlined";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import VideoPlayer from "../Components/Video/VideoPlayer";
import mobilehero from "../assets/images/cake-hero.jpg";
import Newsletter from "../Components/NewsLetter/Newsletter";
import { Link, useNavigate } from "react-router-dom";
import {
  useAppIdentity,
  useCategory,
  useMixAndMatch,
} from "../utils/Constants";
import { ICategory } from "../utils/types";
import { Colors, TextFormatting } from "../utils/utils";
import ButtonComp from "../Components/Button/ButtonComp";
import heroImage from "../assets/images/doughnuts-2.jpg";
import mixAndBake from "../assets/images/mixandbake.jpg";
import { MixBakeSubtitle, TuckItems } from "../utils/CardData";

const Home = () => {
  const color = Colors();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // alert(width)

  const [videoButton, setVideoButton] = useState(false);

  const videoPlayButton = () => {
    setVideoButton(!videoButton);
  };

  const navigate = useNavigate();

  const handleCategory = (catName: string) => {
    const name = TextFormatting(catName);

    navigate(`/menu?category=${name}`);
  };

  const heroMobileHeight = "70vh";

  const AppIdentity = useAppIdentity();

  const category = useCategory();

  const backgroundExt =
    AppIdentity?.attributes.HeroBackground.data.attributes.ext;

  const mixAndMatch = useMixAndMatch();

  const maxLength = 155;

  return (
    <>
      <Stack
        sx={{
          position: "relative",
          display: "flex",
          justifySelf: "center",
          alignSelf: "center",
          flexWrap: "wrap",
          zIndex: 9,
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: { sm: "absolute", xs: "absolute", lg: "relative" },
            height: {
              xs: heroMobileHeight,
              xl: "100vh",
              lg: "100vh",
              md: "80vh",
            },
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
            overflow: "hidden",
          }}
        >
          {isMobile ? (
            <Box
              position="absolute"
              zIndex={-990}
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                minWidth: width,
              }}
              height="70vh"
              width="100%"
              overflow="hidden"
            >
              <img
                src={mobilehero}
                alt=""
                style={{
                  opacity: 0.5,
                  objectFit: "cover",
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                margin: 0,
                padding: 0,
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                borderBottomLeftRadius: "90px",
                borderBottomRightRadius: "90px",
              }}
            >
              <>
                {backgroundExt === ".mp4" && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "5%",
                      right: "5%",
                      zIndex: 999,
                      cursor: "pointer",
                    }}
                    onClick={() => videoPlayButton()}
                  >
                    {videoButton ? (
                      <PlayCircleFilledOutlinedIcon
                        sx={{ fontSize: "50px", color: color.main }}
                      />
                    ) : (
                      <PauseCircleFilledOutlinedIcon
                        sx={{ fontSize: "50px", color: color.main }}
                      />
                    )}
                  </Box>
                )}

                <Box sx={{ overflow: "hidden", height: "100%" }}>
                  {backgroundExt === ".mp4" && (
                    <VideoPlayer
                      videoUrl={`${AppIdentity?.attributes.HeroBackground.data.attributes.url}`}
                      videoState={videoButton}
                    />
                  )}
                  {(backgroundExt === ".png" ||
                    backgroundExt === ".jpg" ||
                    backgroundExt === ".jpeg") &&
                  AppIdentity?.attributes?.HeroBackground?.data?.attributes
                    ?.url ? (
                    <img
                      src={`${AppIdentity?.attributes.HeroBackground.data.attributes.url}`}
                      alt={
                        AppIdentity?.attributes.HeroBackground.data.attributes
                          .alternativeText
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={heroImage}
                      alt="Landing Page"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </Box>
              </>
            </Box>
          )}

          <Box
            sx={{
              position: "absolute",
              textAlign: "center",
            }}
          >
            <Typography
              color="#fff"
              sx={{
                textAlign: "center",
                fontSize: { xs: "2.5rem", xl: "4rem" },
                fontWeight: "bold",
                fontFamily: "Roboto",
                letterSpacing: "3px",
                textTransform: "uppercase",
              }}
            >
              {AppIdentity
                ? AppIdentity?.attributes.HeroTitle
                : "indulge in flavourful delight"}
            </Typography>
            <Typography
              color="#fff"
              sx={{
                fontSize: { xs: "1.3rem", xl: "2rem" },
                fontWeight: "bold",
                fontFamily: "Roboto",
              }}
            >
              {AppIdentity
                ? AppIdentity?.attributes.HeroSubtitle
                : "Delicious Adventure Await"}
            </Typography>
            <Box>
              <Link to="/about-us">
                <Button
                  variant="outlined"
                  sx={{ borderColor: "#fff", mt: "20px", color: "#fff" }}
                >
                  Read More
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Stack>

      <Stack
        pt={{ xs: heroMobileHeight, lg: "50px" }}
        sx={{
          px: { xs: "30px", xl: "60px" },
          overflow: "hidden",
          position: "static",
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              height: { lg: "250px" },
              borderRadius: "20px",
              overflow: "hidden",
              boxShadow:
                "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
              my: "40px",
            }}
          >
            <Grid
              spacing={3}
              display="flex"
              alignItems="center"
              sx={{
                height: "100%",
                flexDirection: { lg: "row", xs: "column" },
              }}
            >
              <Grid xs={12} lg={5} sx={{ height: "100%" }}>
                <Box
                  sx={{
                    height: "100%",
                  }}
                >
                  {mixAndMatch?.attributes?.image?.data?.[0]?.attributes
                    ?.url ? (
                    <img
                      src={`${mixAndMatch?.attributes.image.data[0].attributes.url}`}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={mixAndBake}
                      alt="Mix and Bake"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid
                xs={12}
                lg={7}
                sx={{
                  height: "100%",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  px: "20px",
                  justifyContent: "center", // Centers content vertically
                  alignItems: "center",
                  width: { lg: "60%" },
                  py: { lg: "0px", xs: "20px" },
                }}
              >
                <Box sx={{ ml: "20px" }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: color.dark,
                      fontFamily: "Roboto",
                      fontSize: "1.5rem",
                    }}
                  >
                    {mixAndMatch
                      ? mixAndMatch?.attributes.title
                      : "Mix and Bake"}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: "#5D5D5E",
                      my: "20px",
                      textAlign: "justify",
                    }}
                  >
                    {mixAndMatch
                      ? mixAndMatch?.attributes.subTitle.length > maxLength
                        ? `${mixAndMatch?.attributes.subTitle.slice(
                            0,
                            maxLength
                          )}.....`
                        : mixAndMatch?.attributes.subTitle
                      : MixBakeSubtitle.length > maxLength
                      ? `${MixBakeSubtitle.slice(0, maxLength)}.....`
                      : MixBakeSubtitle}
                  </Typography>
                  <ButtonComp
                    text="Get Started"
                    variant="outlined"
                    href="/mix-and-match"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Stack>
      {/* Tuck In */}
      <Stack
        pt="30px"
        sx={{
          px: { xs: "40px", xl: "60px" },
          overflow: "hidden",
        }}
      >
        <Box sx={{ textAlign: "center", my: "20px" }}>
          <Typography
            variant="h3"
            component="div"
            fontWeight="bold"
            sx={{
              fontFamily: "Roboto",
              color: color.dark,
              textShadow: `0 0 0 ${color.dark}, -1px -1px 0 ${color.dark}, 1px -1px 0 ${color.dark}, -1px 1px 0 ${color.dark}, 1px 1px 0 ${color.dark}`,
            }}
          >
            Take a look and
          </Typography>
          <Typography
            variant="h3"
            component="div"
            fontWeight="bold"
            sx={{
              fontFamily: "Roboto",
              color: "#fff",
              textShadow: `0 0 0 ${color.dark}, -1px -1px 0 ${color.dark}, 1px -1px 0 ${color.dark}, -1px 1px 0 ${color.dark}, 1px 1px 0 ${color.dark}`,
            }}
          >
            tuck in
          </Typography>
        </Box>
        <Container maxWidth="md" sx={{ py: "30px" }}>
          <Grid
            spacing={2}
            gap={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              alignContent: "center",
            }}
          >
            {category
              ? category.map((data: ICategory) => {
                  return (
                    <Grid
                      key={data.id}
                      xs={6}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{
                        textAlign: "center",
                        transition: "transform 0.5s ease",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                      onClick={() => handleCategory(data.attributes.title)}
                    >
                      <Box sx={{ position: "relative" }}>
                        <Box
                          sx={{
                            width: "200px",
                            height: "200px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          {data?.attributes?.image?.data?.[0]?.attributes
                            ?.url && (
                            <img
                              src={`${data?.attributes.image.data[0].attributes.url}`}
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </Box>
                        <Box
                          sx={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            position: "absolute",
                            zIndex: 9,
                            bottom: 10,
                            right: 10,
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          {data?.attributes?.image?.data?.[1]?.attributes
                            ?.url && (
                            <img
                              src={`${data?.attributes.image.data[1].attributes.url}`}
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                      <Typography
                        variant="h5"
                        component="div"
                        fontWeight="bold"
                        sx={{
                          fontFamily: "Roboto",
                          color: color.dark,
                          mt: "15px",
                        }}
                      >
                        {data.attributes.title}
                      </Typography>
                    </Grid>
                  );
                })
              : TuckItems.map((data: any) => {
                  return (
                    <Grid
                      key={data.id}
                      xs={6}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{
                        textAlign: "center",
                        transition: "transform 0.5s ease",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                      onClick={() => handleCategory(data.category)}
                    >
                      <Box sx={{ position: "relative" }}>
                        <Box
                          sx={{
                            width: "200px",
                            height: "200px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          
                            <img
                              src={data.image && data.image}
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                         
                        </Box>
                        <Box
                          sx={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            position: "absolute",
                            zIndex: 9,
                            bottom: 10,
                            right: 10,
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                        
                            <img
                              src={data.handle && data.handle}
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                         
                        </Box>
                      </Box>
                      <Typography
                        variant="h5"
                        component="div"
                        fontWeight="bold"
                        sx={{
                          fontFamily: "Roboto",
                          color: color.dark,
                          mt: "15px",
                        }}
                      >
                        {data.category}
                      </Typography>
                    </Grid>
                  );
                })}
          </Grid>
        </Container>
      </Stack>

      {/* <Stack
        pt="30px"
        sx={{
          px: { xs: "40px", xl: "60px" },
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg" sx={{ py: "60px" }}>
          <Grid
            spacing={2}
            gap={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              aligncontent: "center",
              flexWrap: "wrap",
            }}
          >
            <TaskCard />
          </Grid>
        </Container>
      </Stack>

      <Stack
        my="40px"
        sx={{
          px: { xs: "30px", lg: "60px" },
          display: "flex",
        }}
      >
        <GiftAppComp />
      </Stack> */}

      {/* <Stack
        sx={{
          px: { xs: "30px", lg: "60px" },
          overflow: "hidden",
        }}
      >
        <NewsCards />
      </Stack> */}
      <Newsletter
        title="Get the most out of our Services"
        subTitle=" Want exclusive news, offers and our latest launches sent straight
              to your inbox? Pop your email address below and it’s a deal. We’ll
              even throw in free Gift every so often too."
        policy={
          <>
            Learn more about how we look after and use your data in our{" "}
            <Link to="/legals/privacy-policy">Privacy Policy</Link>
          </>
        }
      />
    </>
  );
};

export default Home;
