import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import Layout from "../Components/Layout/Layout";
import Menu from "../Pages/Menu";
import Product from "../Pages/Product";
// import ShopFinder from "../Pages/ShopFinder";
// import Order from "../Pages/Order";
// import GiftCard from "../Pages/GiftCard";
// import App from "../Pages/App";
// import News from "../Pages/News";
// import DoingGood from "../Pages/DoingGood";
// import Faqs from "../Pages/Faqs";
import TermsAndCondition from "../Pages/TermsAndCondition";
// import Promotion from "../Pages/Promotion";
import AboutUs from "../Pages/AboutUs";
import ContactUs from "../Pages/ContactUs";
import PrivayPolicy from "../Pages/PrivayPolicy";
// import CookiePolicy from "../Pages/CookiePolicy";
// import LatestUpdates from "../Pages/LatestUpdates";
// import ClickAndCollects from "../Pages/ClickAndCollects";
// import Nutrition from "../Pages/Nutrition";
// import Error from "../Pages/Error";
import Error404 from "../Pages/Error404";
import CheckOut from "../Pages/CheckOut";
import CookiePolicy from "../Pages/CookiePolicy";
import Faqs from "../Pages/Faqs";

const RoutesComponents = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout title="Home">
            <Home />
          </Layout>
        }
      />
      <Route
        path="/menu"
        element={
          <Layout title="Menu">
            <Menu />
          </Layout>
        }
      />
      <Route
        path="/mix-and-match"
        element={
          <Layout title="Mix your favourite matches" menuColor>
            <Menu />
          </Layout>
        }
      />
      <Route
        path="/menu/product/:productname"
        element={
          <Layout title="Menu" menuColor>
            <Product />
          </Layout>
        }
      />
      <Route
        path="/mix-and-match/product/:productname"
        element={
          <Layout title="Menu" menuColor>
            <Product />
          </Layout>
        }
      />
      <Route
        path="/about-us"
        element={
          <Layout title="About Us">
            <AboutUs />
          </Layout>
        }
      />
      <Route
        path="/checkout"
        element={
          <Layout title="Checkout Orders" menuColor>
            <CheckOut />
          </Layout>
        }
      />
      <Route
        path="/contact-us"
        element={
          <Layout title="Contact Us">
            <ContactUs />
          </Layout>
        }
      />

      <Route
        path="/privacy-policy"
        element={
          <Layout title="Privacy Policy" menuColor>
            <PrivayPolicy />
          </Layout>
        }
      />
      <Route
        path="/term-and-conditions"
        element={
          <Layout title="Terms and Conditions" menuColor>
            <TermsAndCondition />
          </Layout>
        }
      />
      <Route
        path="/cookie-policy"
        element={
          <Layout title="Cookie Policy" menuColor>
            <CookiePolicy />
          </Layout>
        }
      />

      <Route
        path="/faqs"
        element={
          <Layout title="FAQs">
            <Faqs />
          </Layout>
        }
      />
      {/* <Route
        path="/shop-finder"
        element={
          <Layout title="Shop Finder" menuColor noFooter>
            <ShopFinder />
          </Layout>
        }
      />
      <Route
        path="/order"
        element={
          <Layout title="How to order">
            <Order />
          </Layout>
        }
      />
      <Route
        path="/gift-cards"
        element={
          <Layout title="Gift Cards">
            <GiftCard />
          </Layout>
        }
      />
      <Route
        path="/app"
        element={
          <Layout title="App">
            <App />
          </Layout>
        }
      />
      <Route
        path="/news"
        element={
          <Layout title="Latest News" menuColor>
            <News />
          </Layout>
        }
      />
      <Route
        path="/doing-good"
        element={
          <Layout title="Doing Good">
            <DoingGood />
          </Layout>
        }
      />
     
   
      <Route
        path="/legals/promotions-and-offers"
        element={
          <Layout title="Promotion and Offers">
            <Promotion />
          </Layout>
        }
      />
    
     
    
      <Route
        path="/latest-updates"
        element={
          <Layout title="Latest Updates">
            <LatestUpdates />
          </Layout>
        }
      />
     
      <Route
        path="/nutrition"
        element={
          <Layout title="Nutritional Information">
            <Nutrition />
          </Layout>
        }
      />
      <Route
        path="/click-and-collect"
        element={
          <Layout
            title="Click + Collect - 1. Choose your shop"
            menuColor
            noFooter
          >
            <ClickAndCollects />
          </Layout>
        }
      /> */}

      <Route path="*" element={<Error404 title="Under development" />} />
    </Routes>
  );
};

export default RoutesComponents;
