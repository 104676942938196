import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { Colors } from "../utils/utils";
import useStyles from "../utils/useStyles";
import { Link } from "react-router-dom";

const PrivayPolicy = () => {
  const color = Colors();
  const style = useStyles();
  return (
    <>
      <Stack sx={{ overflow: "hidden", background: "#fff" }}>
        <Box sx={{ mt: "64px" }}>
          <Container maxWidth="md" sx={{}}>
            <Box>
              <Typography
                variant="h2"
                component="div"
                fontWeight={900}
                sx={{
                  color: color.dark,
                  fontFamily: "Roboto",
                  fontSize: { xs: "2rem", lg: "2.8rem" },
                  mb: "30px",
                }}
              >
                Teettam Bakery Privacy Policy
              </Typography>
            </Box>
            <Box>
              <Typography sx={style.text}>
                Effective Date: 1st July, 2024
              </Typography>
              {/* First */}
              <Typography sx={style.privacyText}>Introduction</Typography>
              <Typography sx={style.text}>
                Welcome to Teettam Bakery ("we," "our," "us"). We value the
                privacy of our customers and are committed to protecting your
                personal information. This Privacy Policy explains how we
                collect, use, disclose, and safeguard your data when you visit
                our website or purchase our products.
              </Typography>
              {/* Second */}
              <Typography sx={style.privacyText}>
                Information We Collect
              </Typography>
              <Typography sx={style.textSub}>
                We may collect the following types of information:
              </Typography>
              <Typography sx={style.text}>
                <ul>
                  <li>
                    Personal Identification Information: Name, email address,
                    phone number, postal address.
                  </li>
                  <li>
                    Payment Information: Credit card details, billing
                    information.
                  </li>
                  <li>
                    Technical Data: IP address, browser type, operating system,
                    and website usage data.
                  </li>
                  <li>
                    Order History: Details of products purchased and
                    preferences.
                  </li>
                </ul>
              </Typography>
              {/* Third */}
              <Typography sx={style.privacyText}>
                How We Use Your Information
              </Typography>
              <Typography sx={style.textSub}>
                We use the information we collect for various purposes,
                including:
              </Typography>
              <Typography sx={style.text}>
                <ul>
                  <li>To process and fulfill your orders.</li>
                  <li>To improve our products and services.</li>
                  <li>
                    To communicate with you about your orders, promotions, and
                    offers.
                  </li>
                  <li>To comply with legal obligations.</li>
                  <li>To analyze website traffic and usage patterns.</li>
                </ul>
              </Typography>
              {/* Fourth */}
              <Typography sx={style.privacyText}>
                How We Protect Your Information
              </Typography>
              <Typography sx={style.textSub}>
                We implement various security measures to ensure the safety of
                your personal information, including:
              </Typography>
              <Typography sx={style.text}>
                <ul>
                  <li>Encryption of sensitive data.</li>
                  <li>Secure payment processing.</li>
                  <li>Regular security audits and updates.</li>
                </ul>
              </Typography>
              {/* Fifth */}
              <Typography sx={style.privacyText}>
                Sharing Your Information
              </Typography>
              <Typography sx={style.textSub}>
                We do not sell, trade, or otherwise transfer your personal
                information to outside parties except for:
              </Typography>
              <Typography sx={style.text}>
                <ul>
                  <li>
                    Service Providers: Companies that assist us in operating our
                    website, conducting our business, or servicing you, as long
                    as those parties agree to keep this information
                    confidential.
                  </li>
                  <li>
                    Legal Obligations: When required by law to comply with legal
                    processes or protect our rights.
                  </li>
                </ul>
              </Typography>
              {/* Sixth */}
              <Typography sx={style.privacyText}>Your Rights</Typography>
              <Typography sx={style.textSub}>You have the right to:</Typography>
              <Typography sx={style.text}>
                <ul>
                  <li>Access the personal information we hold about you.</li>
                  <li>Request corrections to any inaccurate information.</li>
                  <li>
                    Request deletion of your personal data (subject to legal
                    obligations).
                  </li>
                  <li>Opt-out of receiving marketing communications.</li>
                </ul>
              </Typography>
              {/* Seventh */}
              <Typography sx={style.privacyText}>Cookies</Typography>
              <Typography sx={style.text}>
                We use cookies to enhance your browsing experience. Cookies are
                small files stored on your device that help us understand how
                you use our site and improve your experience. You can control
                your cookie preferences through your browser settings.
              </Typography>
              {/* Eighth */}
              <Typography sx={style.privacyText}>Third-Party Links</Typography>
              <Typography sx={style.text}>
                Our website may contain links to third-party sites. We are not
                responsible for the privacy practices of these sites. We
                encourage you to read their privacy policies.
              </Typography>
              {/* Nineth */}
              <Typography sx={style.privacyText}>
                Changes to This Privacy Policy
              </Typography>
              <Typography sx={style.text}>
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by updating the effective date at the
                top of this policy. Your continued use of our services after any
                changes indicates your acceptance of the updated policy.
              </Typography>
              {/* Nineth */}
              <Typography sx={style.privacyText}>Contact Us</Typography>
              <Typography sx={style.text}>
                If you have any questions or concerns about this Privacy Policy,
                please contact us <Link to="/contact-us">here</Link>
              </Typography>
            </Box>
          </Container>
        </Box>
      </Stack>
    </>
  );
};

export default PrivayPolicy;
