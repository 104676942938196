import { Colors } from "./utils";

const useStyles = () => {
  const color = Colors();
  return {
    tabs: {
      fontWeight: "bold",
      fontFamily: "Roboto",
      color: color.dark,
    },
    text: {
      mt: "5px",
      fontWeight: 600,
      color: "#5D5D5E",
      mb: "20px",
      textAlign: "justify",
    },
    textHead: {
      fontWeight: 900,
      color: color.dark,
      fontFamily: "Roboto",
      fontSize: "1.5rem",
      // pt: "50px",
    },
    textSub: {
      fontWeight: "bold",
      color: "#535354",
      my: "20px",
      textAlign: "justify",
      fontFamily: "Roboto",
    },
    contactSpeak: {
      fontWeight: 900,
      color: color.dark,
      fontFamily: "Roboto",
      fontSize: "1.1rem",
    },
    error404: {
      fontWeight: 900,
      color: "#5D5D5E",
      fontSize: "1.2em",
      mt: "20px",
    },
    privacyText: {
      fontWeight: 900,
      color: color.dark,
      fontFamily: "Roboto",
      fontSize: "1.5rem",
      // pt: "50px",
    },
  };
};

export default useStyles;
