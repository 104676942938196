import {
  Stack,
  Box,
  Typography,
  Tab,
  Container,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Colors, formatDescription } from "../utils/utils";
import faqs from "../assets/images/faq.png";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { FaqsItems } from "../utils/CardData";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KnowMore from "../Components/Cards/KnowMore";
import Hero from "../Components/Hero/Hero";

const Faqs = () => {
  const color = Colors();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [answertext, setAnswer] = useState("");

  const handleAccordion =
    (panel: string, answertext: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setAnswer(answertext);
    };

  const paragraphs = formatDescription(answertext);

  return (
    <>
      <Stack sx={{ overflow: "hidden", background: "#fff" }}>
        <Hero
          fixed
          title="How can we help?"
          heroHeight="450px"
          backgroundColor={color.dark}
          image={faqs}
          titleMainColor="#FDB71A"
        />

        <Stack sx={{ py: "60px" }}>
          <Box
            sx={{
              textAlign: "center",
              width: { lg: "65%" },
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              fontWeight={900}
              sx={{
                color: color.dark,
                fontFamily: "Roboto, sans-serif",
                fontSize: { xs: "1.5rem", lg: "2.5rem" },
              }}
            >
              FAQs
            </Typography>
            <Typography sx={{ mt: "25px", fontWeight: 600, color: "#5D5D5E" }}>
              View the frequestly asked questions below
            </Typography>
          </Box>
          <Box sx={{ width: "100%", my: "15px" }}>
            <Box sx={{ borderColor: "divider", px: "170px" }}></Box>

            <Container
              sx={{ minHeight: "300px", overflow: "hidden", mt: "30px" }}
            >
              {FaqsItems.map((data: any, index: number) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${index + 1}`}
                  onChange={handleAccordion(`panel${index + 1}`, data.answer)}
                  sx={{
                    my: "15px",
                    background:
                      expanded === `panel${index + 1}` ? color.dark : "#F9F9F9",
                  }}
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === `panel${index + 1}` ? (
                        <CloseOutlinedIcon
                          sx={{ color: expanded ? "#fff" : "" }}
                        />
                      ) : (
                        <AddOutlinedIcon
                          sx={{ color: expanded ? "#fff" : "" }}
                        />
                      )
                    }
                    aria-controls={`panel${index + 1}-content`}
                    id={`panel${index + 1}-header`}
                    sx={{
                      background:
                        expanded === `panel${index + 1}` ? color.dark : "",
                      color:
                        expanded === `panel${index + 1}` ? "#fff" : color.dark,
                      borderRadius:
                        expanded === `panel${index + 1}` ? "10px" : "",
                      py: expanded === `panel${index + 1}` ? "0px" : "5px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {data.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ background: "#fff" }}>
                    {paragraphs &&
                      paragraphs.map((paragraph: any, index: any) => (
                        <Typography
                          key={index}
                          sx={{
                            fontWeight:
                              index % 2 === 0 &&
                              paragraphs.length !== 1 &&
                              data.break
                                ? "bold"
                                : "",
                            color:
                              index % 2 === 0 &&
                              paragraphs.length !== 1 &&
                              data.break
                                ? color.dark
                                : "#5D5D5E",
                            fontFamily: "Roboto, sans-serif",
                            fontSize:
                              index % 2 === 0 &&
                              paragraphs.length !== 1 &&
                              data.break
                                ? { xs: "1.5rem", lg: "1.2rem" }
                                : "16px",
                            mb:
                              (index % 2 === 0 && paragraphs.length !== 1) ||
                              data.break === false
                                ? "20px"
                                : "1px",
                          }}
                        >
                          {paragraph}
                        </Typography>
                      ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Container>
          </Box>
        </Stack>

        <Box
          sx={{
            width: "100%",
            my: "40px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <KnowMore
            buttonText="Get in touch"
            subTitle="That's what we're here for. Get in touch with our Customer Care team below"
            single
            title="Need more help?"
          />
        </Box>
      </Stack>
    </>
  );
};

export default Faqs;
