import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, Stack, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Colors } from "../utils/utils";
import { scroller } from "react-scroll";
import useStyles from "../utils/useStyles";
import { Link } from "react-router-dom";

const TermsAndCondition = () => {
  const [value, setValue] = useState("0");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);

    let label;
    if (newValue === "1") {
      label = "Digital";
    }
    if (newValue === "2") {
      label = "Account";
    }
    if (newValue === "3") {
      label = "click+collect";
    }
    if (newValue === "4") {
      label = "b2b";
    }
    if (newValue === "4") {
      label = "gifts";
    }

    scroller.scrollTo(label as string, {
      duration: 1000,
      delay: 50,
      smooth: true,
      offset: -135,
    });
  };

  const color = Colors();

  const style = useStyles();

  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Stack sx={{ overflow: "hidden", background: "#fff" }}>
        <Box sx={{ mt: "64px" }}>
          <TabContext value={value}>
            <Container maxWidth="md" sx={{}}>
              <Box>
                <Typography
                  variant="h2"
                  component="div"
                  fontWeight={900}
                  sx={{
                    color: color.dark,
                    fontFamily: "Roboto",
                    fontSize: { xs: "2rem", lg: "2.8rem" },
                    mb: "30px",
                  }}
                >
                  Teettam Bakery Terms and Conditions
                </Typography>
              </Box>
              <Box>
                <Typography sx={style.text}>
                  Effective Date: 1st July, 2024
                </Typography>
              </Box>

              <Box id="Digital">
                {/* First */}
                <Typography variant="h6" sx={style.privacyText}>
                  1. Introduction
                </Typography>
                <Typography sx={style.text}>
                  Welcome to Teettam Bakery ("we," "our," "us"). These Terms and
                  Conditions govern your use of our website and services. By
                  accessing or using our website and services, you agree to be
                  bound by these terms.
                </Typography>
                {/* Second */}
                <Typography variant="h6" sx={style.privacyText}>
                  2. Use of Our Website
                </Typography>
                <Typography sx={style.text}>
                  <ul>
                    <li>
                      Eligibility: You must be at least 16 years old to use our
                      website and services. Account Security: If you create an
                      account, you are responsible for maintaining the
                      confidentiality of your login information and for all
                      activities that occur under your account.
                    </li>
                    <li>
                      Prohibited Activities: You agree not to engage in any
                      activity that interferes with or disrupts our website or
                      services, or infringes on our intellectual property
                      rights.
                    </li>
                  </ul>
                </Typography>
                {/* Third */}
                <Typography variant="h6" sx={style.privacyText}>
                  3. Orders and Payments
                </Typography>
                <Typography sx={style.text}>
                  <ul>
                    <li>
                      Order Acceptance: All orders are subject to acceptance and
                      availability. We reserve the right to refuse or cancel any
                      order at our discretion.
                    </li>
                    <li>
                      Pricing: All prices are listed in British Pounds Sterling
                      and are subject to change without notice. Prices do not
                      include taxes or shipping costs, which will be added to
                      your total at checkout.
                    </li>
                    <li>
                      Payment: We accept all Credit and Debit cards (except for
                      America Express Cards). Payment must be received in full
                      before we dispatch your order.
                    </li>
                  </ul>
                </Typography>
                {/* Fourth */}
                <Typography variant="h6" sx={style.privacyText}>
                  4. Shipping and Delivery
                </Typography>
                <Typography sx={style.text}>
                  <ul>
                    <li>
                      Shipping: We offer various shipping options. Shipping
                      costs and delivery times will be calculated at checkout.
                    </li>
                    <li>
                      Delivery: We aim to deliver your order within the
                      estimated delivery time, but delays may occur. We are not
                      responsible for delays caused by third-party carriers.
                    </li>
                  </ul>
                </Typography>
                {/* Fifth */}
                <Typography variant="h6" sx={style.privacyText}>
                  5. Returns and Refunds
                </Typography>
                <Typography sx={style.text}>
                  <ul>
                    <li>
                      Returns: If you are not satisfied with your purchase, you
                      may return it within 24 hours of receipt for a refund or
                      exchange. Items must be returned in their original
                      condition.
                    </li>
                    <li>
                      Refunds: Refunds will be processed within 7 working days
                      of receiving the returned item. Shipping costs are
                      non-refundable.
                    </li>
                  </ul>
                </Typography>
                {/* Sixth */}
                <Typography variant="h6" sx={style.privacyText}>
                  6. Intellectual Property
                </Typography>
                <Typography sx={style.text}>
                  <ul>
                    <li>
                      Ownership: All content on our website, including text,
                      graphics, logos, and images, is the property of Teettam
                      Bakery and is protected by copyright and trademark laws.
                    </li>
                    <li>
                      Use: You may not use, reproduce, or distribute any content
                      from our website without our prior written permission.
                    </li>
                  </ul>
                </Typography>
                {/* Seventh */}
                <Typography variant="h6" sx={style.privacyText}>
                  7. Limitation of Liability
                </Typography>
                <Typography sx={style.text}>
                  <ul>
                    <li>
                      Disclaimer: Our website and services are provided "as is"
                      without warranties of any kind, either express or implied.
                    </li>
                    <li>
                      Limitation: To the maximum extent permitted by law, we
                      shall not be liable for any indirect, incidental, or
                      consequential damages arising out of or in connection with
                      your use of our website or services.
                    </li>
                  </ul>
                </Typography>
                {/* Eighth */}
                <Typography variant="h6" sx={style.privacyText}>
                  8. Indemnification
                </Typography>
                <Typography sx={style.text}>
                  You agree to indemnify and hold harmless Teettam Bakery, its
                  affiliates, and their respective officers, directors,
                  employees, and agents from any claims, liabilities, damages,
                  and expenses arising out of your use of our website or
                  services.
                </Typography>
                {/* Nineth */}
                <Typography variant="h6" sx={style.privacyText}>
                  9. Governing Law
                </Typography>
                <Typography sx={style.text}>
                  These Terms and Conditions shall be governed by and construed
                  in accordance with the laws of the United Kingdom, without
                  regard to its conflict of law principles.
                </Typography>
                {/* Tenth */}
                <Typography variant="h6" sx={style.privacyText}>
                  10. Changes to These Terms
                </Typography>
                <Typography sx={style.text}>
                  We may update these Terms and Conditions from time to time. We
                  will notify you of any changes by updating the effective date
                  at the top of these terms. Your continued use of our services
                  after any changes indicates your acceptance of the updated
                  terms.
                </Typography>
                {/* Eleventh */}
                <Typography variant="h6" sx={style.privacyText}>
                  11. Contact Us
                </Typography>
                <Typography sx={style.text}>
                  If you have any questions or concerns about these Terms and
                  Conditions, please contact us at{" "}
                  <Link to="/contact-us">here</Link>
                </Typography>
              </Box>
            </Container>
          </TabContext>
        </Box>
      </Stack>
    </>
  );
};

export default TermsAndCondition;
