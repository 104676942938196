import { Stack, Box, Typography, useTheme, Grid } from "@mui/material";
import Logo from "../svg/burgerLogo";
import { Link } from "react-router-dom";
import { FC } from "react";
import { SiTiktok } from "react-icons/si";

interface FooterProps {
  identity: string;
}

const Footer: FC<FooterProps> = ({ identity }) => {
  const theme = useTheme();
  const color = theme.palette.primary;
  const currentYear = new Date().getFullYear();
  const style = {
    text: {
      fontWeight: 500,
      my: "15px",
      fontFamily: "Roboto",
      fontSize: "13px",
      color: "#fff",
    },
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "350px",
          background: color.dark,
          position: "relative",
          marginBottom: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              mt: "50px",
              fontFamily: "Barlow Condensed, sans-serif",
              fontWeight: 800,
              lineHeight: 1,
              color: "#fff",
              letterSpacing: 4,
              textShadow: `0 0 0 #FDB71A, -1px -1px 0 #FDB71A, 1px -1px 0 #FDB71A, -1px 1px 0 #FDB71A, 1px 1px 0 #FDB71A`,
            }}
          >
            FILL YOUR FEEDS
          </Typography>
          <Typography
            variant="h5"
            sx={{ mt: "30px", fontWeight: 900, color: "#FDB71A" }}
          >
            Follow {identity}
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: { xs: "50%", lg: "15%" },
              height: "50px",
              justifySelf: "center",
              flexWrap: "wrap",
              alignSelf: "center",
              justifyContent: "space-between",
              mt: "30px",
            }}
          >
            <Link
              to="https://www.facebook.com/profile.php?id=61563984394805"
              target="_blank"
            >
              <Box sx={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 37 37"
                  width={41}
                >
                  <path
                    fill="#fff"
                    d="M36.5 18.504a18.19 18.19 0 0 0-2.89-9.737 18.032 18.032 0 0 0-7.648-6.638A17.901 17.901 0 0 0 15.965.681a17.95 17.95 0 0 0-9.198 4.196 18.122 18.122 0 0 0-5.505 8.52 18.218 18.218 0 0 0-.077 10.161 18.127 18.127 0 0 0 5.373 8.604 17.954 17.954 0 0 0 9.134 4.338V23.77h-4.57v-5.266h4.57V14.49c0-4.565 2.685-7.087 6.8-7.087 1.35.019 2.696.138 4.028.356v4.483h-2.273a2.594 2.594 0 0 0-2.145.715 2.627 2.627 0 0 0-.786 2.132v3.416h4.992l-.8 5.264h-4.192V36.5a18.049 18.049 0 0 0 10.888-6.183A18.253 18.253 0 0 0 36.5 18.505Z"
                  ></path>
                </svg>
              </Box>
            </Link>
            <Link
              to="https://www.instagram.com/teettambakeryy?utm_source=qr&igsh=ZjRkdDI4a3c0d2Rw"
              target="_blank"
            >
              <Box sx={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 128 128"
                  width={41}
                >
                  <path
                    fill="#fff"
                    d="M77.3 64c0 7.4-6 13.3-13.3 13.3s-13.3-6-13.3-13.3c0-7.4 6-13.3 13.3-13.3 7.4 0 13.3 5.9 13.3 13.3zm20-18.3v36.7c0 8.3-6.7 15-15 15H45.7c-8.3 0-15-6.7-15-15V45.7c0-8.3 6.7-15 15-15h36.7c8.2 0 14.9 6.7 14.9 15zM84 64c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm5.8-21.2c0-2.5-2.1-4.6-4.6-4.6s-4.6 2.1-4.6 4.6 2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6zM128 64c0 35.3-28.7 64-64 64S0 99.3 0 64 28.7 0 64 0s64 28.7 64 64zm-24-18.3c0-12-9.7-21.7-21.7-21.7H45.7C33.7 24 24 33.7 24 45.7v36.7c0 11.9 9.7 21.7 21.7 21.7h36.7c11.9 0 21.7-9.7 21.7-21.7V45.7z"
                  ></path>
                </svg>
              </Box>
            </Link>
            <Link to="https://www.tiktok.com/@teettambakery" target="_blank">
              <Box sx={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 150 150"
                  width={41}
                >
                  <path
                    fill="#fff"
                    d="M92.6 2.2C83.7.1 75.9-.5 67 .4 40.6 3.2 17 20.6 6.2 45.2-.7 61.1-1.9 79.4 3 96.2c6.1 21 21.8 38.7 42.1 47.6 15.9 6.9 34.2 8.1 51.1 3.2 25.2-7.3 45.1-28 51.6-53.7C158 53 133 11.8 92.6 2.2zm20.6 53.3v11.7c-7.8 0-15-2.5-20.9-6.7v30.6c0 15.3-12.4 27.7-27.7 27.7-5.9 0-11.4-1.9-15.9-5-7.1-5-11.8-13.3-11.8-22.7 0-15.3 12.4-27.7 27.7-27.7 1.3 0 2.5.1 3.7.3V79c-1.2-.4-2.4-.6-3.7-.6-7 0-12.6 5.7-12.6 12.6 0 4.9 2.8 9.1 6.8 11.2 1.8.9 3.7 1.5 5.9 1.5 6.8 0 12.4-5.4 12.6-12.2V31.2h15c0 1.3.1 2.6.4 3.8 1.1 5.7 4.5 10.6 9.2 13.7 3.3 2.1 7.2 3.4 11.4 3.4v3.4z"
                  ></path>
                </svg>
              </Box>
            </Link>
            {/* <Link to="" target="_blank">
              <Box sx={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 150 150"
                  width={41}
                >
                  <path
                    fill="#fff"
                    d="M66.9.4c-26.4 2.8-50 20.2-60.8 44.8-6.9 15.9-8.1 34.2-3.2 51 6.1 21 21.8 38.7 42.1 47.6 15.9 6.9 34.2 8.1 51.1 3.2 25.2-7.3 45.1-28 51.6-53.7C157.9 53 132.9 11.8 92.5 2.2 83.6.1 75.8-.5 66.9.4zm1 46.6c6.4 8.5 11.8 15.5 12 15.5s6.9-7 14.7-15.5L109 31.5h8.2l-1.8 1.9c-1 1-7.5 8.1-14.5 15.7S87.1 64 85.8 65.4L83.4 68l18.8 25c10.3 13.8 18.8 25.2 18.8 25.4 0 .3-6.2.4-13.6.4l-13.7-.1-12.7-17c-8.7-11.9-12.9-17-13.3-16.7-.3.3-7.4 7.9-15.8 17l-15.3 16.6h-3.9c-2.2 0-3.9-.1-3.9-.3 0-.1 8-8.9 17.7-19.4 9.7-10.5 17.7-19.2 17.8-19.4.1-.2-7.9-11.1-17.7-24.2-9.8-13-17.8-23.8-17.8-23.9 0-.1 6.2-.1 13.7-.1h13.7L67.9 47zm-26.7-8.8c.2.4 12.8 17.3 28 37.5l27.6 36.9 6.2.1c3.8 0 6.1-.2 6-.5 0-.3-12.6-17.2-27.9-37.6L53.3 37.5h-6.2c-5.2 0-6.2.1-5.9.7z"
                  ></path>
                </svg>
              </Box>
            </Link> */}
          </Box>
        </Box>
        <Stack sx={{ mt: "50px" }}>
          <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Grid item xs={6} lg={3}>
              <Box sx={{ color: "#fff", textAlign: "center" }}>
                <Link to="/about-us">
                  <Typography sx={style.text}>ABOUT US</Typography>
                </Link>
                <Link to="/contact-us">
                  <Typography sx={style.text}>CONTACT US</Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box sx={{ color: "#fff", textAlign: "center" }}>
                <Link to="/cookie-policy">
                  <Typography sx={style.text}>COOKIE POLICY</Typography>
                </Link>
                <Link to="/privacy-policy">
                  <Typography sx={style.text}>PRIVACY POLICY</Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box sx={{ color: "#fff", textAlign: "center" }}>
                <Link to="/faqs">
                  <Typography sx={style.text}>FAQS</Typography>
                </Link>
                <Link to="/term-and-conditions">
                  <Typography sx={style.text}>T&CS</Typography>
                </Link>
                {/* <Link to="/legals/promotions-and-offers">
                  <Typography sx={style.text}>PROMOTIONS AND OFFERS</Typography>
                </Link> */}
              </Box>
            </Grid>
          </Grid>
        </Stack>
        <Box sx={{ textAlign: "center", pt: { xs: "40px", lg: "40px" } }}>
          <Typography
            sx={{ color: "#fff", fontWeight: 500, fontFamily: "Roboto" }}
          >
            Copyright © {currentYear} {identity}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
