import { Box, Typography, useTheme } from "@mui/material";
import React, { FC } from "react";
import ButtonComp from "../Button/ButtonComp";

interface KnowMoreProps {
  title: string;
  subTitle: string;
  buttonText: string;
  link?: string;
  single?: boolean;
}

const KnowMore: FC<KnowMoreProps> = ({
  title,
  subTitle,
  buttonText,
  link,
  single,
}) => {
  const theme = useTheme();
  const color = theme.palette.primary;
  return (
    <>
      <Box
        sx={{
          background: "#F7F7F7",
          width: single ? { lg: "60%", xs: "93%" } : { lg: "45%", xs: "93%" },
          height: "250px",
          borderRadius: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          py: {lg: "20px", xs: '0px'},
        }}
      >
        <Typography
          variant="h5"
          component="div"
          fontWeight={900}
          sx={{
            color: color.dark,
            fontFamily: "Roboto, sans-serif",
            fontSize: "2rem"
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            mx: {lg: "60px", xs: '50px'},
            my: "30px",
            fontWeight: 600,
            color: "#5D5D5E",
            textAlign: "center",
          }}
        >
          {subTitle}
        </Typography>
        <ButtonComp
          text={buttonText}
          variant="contained"
          color="#000"
          background="#FDB71A"
          href={link}
        />
      </Box>
    </>
  );
};

export default KnowMore;
