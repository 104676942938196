import { gql } from "@apollo/client";

export interface IAppIdentity {
  id: number;
  attributes: {
    identity: string;
    HeroTitle: string;
    HeroSubtitle: string;
    logo: {
      data: {
        attributes: {
          url: string;
          alternativeText: string;
        };
      };
    };
    HeroBackground: {
      data: {
        attributes: {
          url: string;
          ext: string;
          alternativeText: string;
        };
      };
    };
  };
}

export interface ICategory {
  id: number;
  attributes: {
    title: string;
    image: {
      data: Array<{
        attributes: {
          alternativeText: string;
          url: string;
        };
      }>;
    };
    products: {
      data: IProducts[];
    };
  };
}

export interface IProducts {
  id: number;
  attributes: {
    title: string;
    slug: string;
    price: number;
    desc: Array<{}>;
    content: [];
    ingredients: Array<{
      name: string;
      input: string;
      mixmatch: Array<{
        name: string;
        price: number;
      }>;
    }>;
    image: {
      data: Array<{
        attributes: {
          alternativeText: string;
          url: string;
        };
      }>;
    };
    category: {
      data: {
        attributes: {
          title: string;
          isVisible: boolean;
        };
      };
    };
  };
}

export interface IMixMatch {
  id: number;
  attributes: {
    title: string;
    subTitle: string;
    image: {
      data: Array<{
        attributes: {
          alternativeText: string;
          url: string;
        };
      }>;
    };
    products: {
      data: IProducts[];
    };
  };
}

export interface CartItem {
  imageSrc: string;
  title: string;
  price: number;
  pathName: string;
  slug: string;
  ingredients: {}
}

export const GET_CATEGORY = gql`
  query getCategory {
    categories {
      data {
        id
        attributes {
          title
          image {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          products {
            data {
              id
              attributes {
                title
                price
                slug
                desc
                image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                category {
                  data {
                    attributes {
                      title
                      isVisible
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_PRODUCT_CATEGORY = gql`
  query getCategory($title: String) {
    categories(filters: { title: { eq: $title } }) {
      data {
        id
        attributes {
          title
          image {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          products {
            data {
              id
              attributes {
                title
                price
                slug
                desc
                ingredients
                image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                category {
                  data {
                    attributes {
                      title
                      isVisible
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProduct($slug: String) {
    products(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          title
          price
          slug
          desc
          ingredients
          content
          image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          category {
            data {
              attributes {
                title
                isVisible
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MIXANDMATCH = gql`
  query getMixMatch {
    mixAndMatch {
      data {
        id
        attributes {
          title
          subTitle
          image {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          products {
            data {
              id
              attributes {
                title
                price
                slug
                desc
                image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                category {
                  data {
                    attributes {
                      title
                      isVisible
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_APP_SETTINGS = gql`
  query getAppSetting {
    appSetting {
      data {
        id
        attributes {
          identity
          HeroTitle
          HeroSubtitle
          logo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          HeroBackground {
            data {
              attributes {
                url
                ext
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MIXMATCH_PRODUCT = gql`
  query getCategory($title: String) {
    categories(filters: { title: { eq: $title } }) {
      data {
        id
        attributes {
          title
          image {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          products(
            filters: { ingredients: { notNull: true }, id: { notNull: true } }
          ) {
            data {
              id
              attributes {
                title
                price
                slug
                desc
                ingredients
                image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                category {
                  data {
                    attributes {
                      title
                      isVisible
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const CREATE_DOCUMENT_MUTATION = gql`
  mutation CreateDocument($input: createDocumentInput!) {
    createDocument(input: $input) {
      document {
        id
        firstName
        lastName
        email
        product
        message
      }
    }
  }
`;