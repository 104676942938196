import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import RoutesComponents from "./routes/RoutesComponents";
import "@fontsource/quicksand";
import "@fontsource/barlow";
import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider } from "@apollo/client";
import { apolloClientProvider } from "./utils/apolloHelper";
import ScrollToTop from "./Components/ScrollToTop";

const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: "Quicksand",
    },
    palette: {
      primary: {
        main: "#00558F",
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <BrowserRouter>
            <ApolloProvider client={apolloClientProvider}>
              <ScrollToTop />
              <RoutesComponents />
            </ApolloProvider>
          </BrowserRouter>
        </HelmetProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
