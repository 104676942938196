import { useState, useEffect } from "react";
import {
  GET_APP_SETTINGS,
  GET_CATEGORY,
  GET_MIXANDMATCH,
  GET_MIXMATCH_PRODUCT,
  GET_PRODUCT_CATEGORY,
  GET_PRODUCTS,
  IAppIdentity,
  ICategory,
  IMixMatch,
  IProducts,
} from "./types";
import { useQuery } from "@apollo/client";

export const useCategory = () => {
  const [category, setCategory] = useState<ICategory[]>();

  const { data } = useQuery(GET_CATEGORY);

  useEffect(() => {
    if (data) {
      const categoryData = data?.categories.data;
      setCategory(categoryData);
    }
  }, [data]);

  return category;
};

export const useCategoryMix = () => {
  const [category, setCategory] = useState<ICategory[]>();
  const { data } = useQuery(GET_MIXMATCH_PRODUCT);

  useEffect(() => {
    if (data) {
      const categoryData = data?.categories.data;
      setCategory(categoryData);
    }
  }, [data]);

  return category;
};


export const useCategoryProduct = (title: string) => {
  const [category, setCategory] = useState<ICategory[]>([]);

  const { data } = useQuery(GET_PRODUCT_CATEGORY, {
    variables: { title },
  });

  useEffect(() => {
    if (data) {
      const categoryData = data?.categories.data;

      setCategory(categoryData);
    }
  }, [data]);

  return category;
};

export const useProduct = (title: string) => {
  const [product, setProduct] = useState<IProducts | null>(null);
  const { data } = useQuery(GET_PRODUCTS, {
    variables: { slug: title },
  });

  useEffect(() => {
    if (data) {
      const productData = data?.products.data[0];
      setProduct(productData);
    }
  }, [data]);

  return product;
};

export const useMixAndMatch = () => {
  const [mixMatch, setMixMatch] = useState<IMixMatch | null>(null);

  const { data } = useQuery(GET_MIXANDMATCH);

  useEffect(() => {
    if (data) {
      const mixMatchdata = data?.mixAndMatch.data;
      setMixMatch(mixMatchdata);
    }
  }, [data]);

  return mixMatch;
};

export const useAppIdentity = () => {
  const [AppIdentity, setAppIdentity] = useState<IAppIdentity>();

  const { data } = useQuery(GET_APP_SETTINGS);

  useEffect(() => {
    if (data) {
      const AppSetting = data?.appSetting.data;

      setAppIdentity(AppSetting);
    }
  }, [data]);

  return AppIdentity;
};
