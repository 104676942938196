import latest from "../assets/images/latest-update.jpg";
import staff from "../assets/images/Staff.jpg";
import daystart from "../assets/images/cake-1.jpg";
import bread1 from "../assets/images/bread1.jpg";
import bread2 from "../assets/images/bread2.jpeg";
import pastry1 from "../assets/images/pastry1.jpg";
import pastry2 from "../assets/images/pastry2.jpg";
import burger3 from "../assets/images/burgers-3.jpg";
import hamburger from "../assets/images/hamburger1.png";
import snacks1 from "../assets/images/snacks1.jpeg";
import snacks2 from "../assets/images/snacks2.jpg";
import shop from "../assets/images/shop1.jpg";

export const CardData = [
  {
    image: latest,
    text: "Latest Updates",
    to: "latest-updates",
  },
  {
    image: staff,
    text: "The food Pledge",
    to: "doing-good",
  },
  {
    image: daystart,
    text: "Start your day on a roll",
    to: "menu",
  },
];

export const MixBakeSubtitle = 'Discover the Ultimate Bakery Experience: Mix & Bake your Favourite Flavours to Create Perfectly Personalized Treats.'

export const TuckItems = [
  {
    id: "1",
    category: "Bread",
    image: bread1,
    handle: bread2,
    children: [
      {
        image: hamburger,
        title: "Meat Fresh Burger",
        description:
          "Freshly ground Espresso Coffee./Short, strong and full of beans./ If you’re after a no-nonsense, does what it says on the tin coffee boost, this one should do just the trick./ Of course, at the heart of any great Americano, is a high quality, freshly ground Espresso./ Ours is made from our own unique blend of mild, high grown and rich tasting Arabica and Robusta beans./ Not only does it taste INCREDIBLE but our coffee beans are 100% Fairtrade./ Tastes good, does good.",
      },
      {
        image: bread2,
        title: "Spiced Burger",
        description:
          "Freshly ground Espresso Coffee./Short, strong and full of beans./ If you’re after a no-nonsense, does what it says on the tin coffee boost, this one should do just the trick./ Of course, at the heart of any great Americano, is a high quality, freshly ground Espresso./ Ours is made from our own unique blend of mild, high grown and rich tasting Arabica and Robusta beans./ Not only does it taste INCREDIBLE but our coffee beans are 100% Fairtrade./ Tastes good, does good.",
      },
      {
        image: burger3,
        title: "Sweetened Burger",
        description:
          "Freshly ground Espresso Coffee.Short, strong and full of beans. If you’re after a no-nonsense, does what it says on the tin coffee boost, this one should do just the trick./ Of course, at the heart of any great Americano, is a high quality, freshly ground Espresso./ Ours is made from our own unique blend of mild, high grown and rich tasting Arabica and Robusta beans./ Not only does it taste INCREDIBLE but our coffee beans are 100% Fairtrade./ Tastes good, does good.",
      },
      {
        image: pastry1,
        title: "Sweetened Burger",
        description:
          "Freshly ground Espresso Coffee.Short, strong and full of beans. If you’re after a no-nonsense, does what it says on the tin coffee boost, this one should do just the trick./ Of course, at the heart of any great Americano, is a high quality, freshly ground Espresso./ Ours is made from our own unique blend of mild, high grown and rich tasting Arabica and Robusta beans./ Not only does it taste INCREDIBLE but our coffee beans are 100% Fairtrade./ Tastes good, does good.",
      },
    ],
  },
  {
    id: "2",
    category: "Pastry",
    image: pastry1,
    handle: pastry2,
    children: [
      {
        image: pastry1,
        title: "Chocolate Cup Cake",
      },
      {
        image: pastry2,
        title: "Vanilla Cup Cake burger",
      },
    ],
  },
  {
    id: "3",
    category: "Snacks",
    image: snacks1,
    handle: snacks2,
    children: [
      {
        image: snacks1,
        title: "Sliced Cake",
      },
    ],
  }, 
];

export const OrderItems = [
  {
    image: shop,
    title: "Choose your shop",
    subTitle:
      "First things first, tell us which Food shop you’d like to pick up your tasty Click + Collect order from.",
    rtl: false,
    buttonText: "Order on Click + Collect",
    href: "/click-and-collect",
  },
  {
    image: shop,
    title: "Book your time slot",
    subTitle:
      "Let us know what time you’d like to pick up your Food. We allocate five-minute slots so each Click + Collect order is prepared and packed fresh – especially for you.",
    rtl: true,
    buttonText: "Order on Click + Collect",
    href: "/click-and-collect",
  },
  {
    image: shop,
    title: "Grab your Orders",
    subTitle:
      "Sausage Roll superfan? Yearning for a Yum Yum? Browse our menu to order your Food faves to your order. ",
    rtl: false,
    buttonText: "Order on Click + Collect",
    href: "/click-and-collect",
  },
  {
    image: shop,
    title: "Pick up a meal deal",
    subTitle: "Get more ‘Mmmmm’ for your money by making it a meal deal.",
    rtl: true,
    buttonText: "Order on Click + Collect",
    href: "/click-and-collect",
  },
];

export const CardItems = [
  {
    image: shop,
    title: "For your VIPs",
    subTitle:
      "The perfect gift for the ultimate Food fan!/ Our Food High Roller Gift Card comes in an exclusive Food Sausage Roll gift bag and has a tasty £50 value.",
    rtl: false,
    buttonText: "Buy a High Roller Gift Card",
    href: "",
  },
  {
    image: shop,
    title: "Postal Prezzie",
    subTitle:
      "Surprise your loved ones with a Food Gift Card straight to their doorstep. /Choose from a range of designs, from as little as £5 or as much as £50.",
    rtl: true,
    buttonText: "Buy a post gift card",
    href: "",
    titleColor: "#FDB71A",
  },
  {
    image: shop,
    title: "Inbox goodie",
    subTitle:
      "Why not send a Food Gift Card by email for instant delivered happiness?/ There’s plenty of designs to choose from and you can even personalise, and add your own message.",
    rtl: false,
    buttonText: "Buy an email gift card",
    href: "",
  },
  {
    image: shop,
    title: "Food by tasty text",
    subTitle:
      "Our text gift cards arrive in a matter of minutes and are the perfect way to say 'Thank You', 'Happy Birthday' or 'Just because' via text – light up someone’s life as well as their phone.",
    rtl: true,
    buttonText: "Buy a text gift card",
    href: "",
    titleColor: "#FDB71A",
  },
];

export const FaqsItems = [
  {
    question: "What types of cookies do you offer?",
    answer:
      "We offer a variety of cookies, including classic chocolate chip, oatmeal raisin, double chocolate, peanut butter, and seasonal specials. Check our menu for the full list.",
  },
  {
    question: "Do you have gluten-free options?",
    answer:
      "Yes, we offer a selection of gluten-free cookies. Look for the gluten-free label on our menu.",
  },
  {
    question: "Can I place a custom order?",
    answer:
      "Absolutely! We take custom orders for special occasions, including personalized messages and decorations. Contact us at info@teettam.co.uk to discuss your requirements.",
  },
  {
    question: "Do you offer delivery services?",
    answer:
      "Yes, we offer delivery services within the local area. You can place an order through our website menu page.",
  },
  {
    question: "What are your opening hours?",
    answer:
      "Our bakery is open Monday to Friday from 8 AM to 6 PM, and on weekends from 9 AM to 5 PM.",
  },
  {
    question: "How can I contact customer service?",
    answer:
      "You can reach our customer service team via email at info@teettam.co.uk or by using our contact us page",
  },
  {
    question: "Do you offer vegan options?",
    answer:
      "Yes, we have a range of vegan cookies available. Please check our menu for details.",
  },
  {
    question: "Where are you located?",
    answer:
      "We are located at 103 Shields Road Newcastle upon Tyne NE6 1DN, United Kingdom. You can find more about us on our contact page.",
  },
  {
    question: "Can I cancel or modify my order?",
    answer:
      "Yes, you can cancel or modify your order up to 24 hours before the scheduled delivery or pickup time. Please contact us as soon as possible to make any changes.",
  },
];

export const AppItems = [
  {
    image: shop,
    title: "Untapped free Gigs",
    subTitle:
      "We'd like to show you just how much you mean to us, that’s why you’ll be rewarded when you shop./ From sandwiches to sweet treats, hot drinks and bakes, each time you buy a product, you’ll get a stamp./ Collect 9 stamps in a category, and the 10th item will be free*./ *Some products aren't eligible for stamps. See full T&Cs page.",
    rtl: false,
  },
  {
    image: shop,
    title: "Make it snappy",
    subTitle:
      "Forget about the lunchtime rush./ The App makes getting rewarded for enjoying your flaky favourites even easier with Click + Collect./ Simply tell us what you want and when you want it, then pop down to your selected shop to grab the goods.",
    rtl: true,
  },
  {
    image: shop,
    title: "All mapped out",
    subTitle:
      "In need of your next Food fix?/ We don’t blame you./ Our Shop Finder has all the information you'll ever need to locate your nearest shop and find out when it's open.",
    rtl: false,
  },
  {
    image: shop,
    title: "Birthday Appreciation",
    subTitle:
      "No birthday is complete without a Food./ That’s why we’ll give you a free sweet treat every year on your birthday./ Redeem it in our shops to make it one to remember.",
    rtl: true,
  },
  {
    image: shop,
    title: "Treats on us",
    subTitle:
      "If that wasn’t enough to convince you, you’ll also get a free hot drink just for downloading the app./ Yep, it's completely free and will be your first of many tasty rewards.",
    rtl: false,
  },
];

export const DoingGoods = [
  {
    name: "Greggs Foundation Breakfast Clubs",
    image: shop,
    title: "You may have already heard about The Foundation Breakfast Clubs.",
    subTitle:
      "They started all the way back in 1999, to ensure that thousands of children could have a healthy start to the day. Breakfast Clubs have been fueling kids across the nation for a more productive day at school./ By the end of 2023, on every school day, we provided a free meal to more than 62,000 children across our network of 896 Breakfast Clubs./ We’re incredibly proud of The Food Foundation Breakfast Clubs, and we believe that the rising costs of living mean it’s more important than ever to help our local communities./ That’s why by 2025, we hope to be reaching 70,000 children on each school day./ For more information on Breakfast Clubs or the fantastic work of The Food Foundation click below.",
    rtl: true,
    buttonText: "Visit the Foundation",
    href: "",
    titleColor: "#FDB71A",
  },
  {
    name: "Food Waste",
    image: shop,
    title:
      "If you know Food, you know we really love food - and that’s why we send none of it to landfill. By 2025, we’re also committed to 25% less food surplus than in 2018.",
    subTitle:
      "Our famous bakes and Sausage Rolls are all freshly baked, while our selection of sandwiches are prepared daily in shop to make sure your breakfast, lunch or dinner is packed full of fresh flavour and fun. Because much of our tasty menu is fresh, a lot goes on behind the scenes to prevent surplus. We have brilliant shop teams who manage stock levels, while a forecasting system that helps us plan so we know what our customers will want so we don’t over order./ We achieved our food waste reduction target in all of our manufacturing sites in 2023 and we’re still working hard on cutting food surplus within our shops. More than a third of our unsold food has a second chance to be enjoyed via donations to good causes, such as Food Outlets, charity partners and foodbanks./ We also work with Too Good To Go, a brilliant food app that offers our customers a ‘Magic Bag’ of unsold food worth around £8 for under £3.",
    rtl: false,
  },
  {
    name: "Outlets",
    image: shop,
    title:
      "As closing time approaches in many of our shops, unsold food is collected and distributed to one of our Food Outlets to be sold the next day at a tasty discount.",
    subTitle:
      "As well as acting as our unsung food waste heroes, Outlet shops are a safe haven for Food fans on a tight budget. We open Food Outlet shops in areas where families are likely to be hit hardest by deprivation issues. To protect those communities, we invest a portion of the profit from Outlet shops back into those areas via The Food Foundation by partnering or giving grants to local organisations./ In 2023, we opened 6 new Food Outlets and we have more on the way in 2024. By the end of 2025, we commit to having 50 Food Outlets providing affordable food and donating a profit share to give back to the local community./ To see if there’s a Food Outlet near you, click below.",
    rtl: true,
    buttonText: "Find our Outlet",
    href: "",
    titleColor: "#FDB71A",
  },
  {
    name: "Healthier choices",
    image: shop,
    title:
      "Now, we don’t want to blow our own trumpet, but we might just have this whole delicious food lark sussed.",
    subTitle:
      "Our aim is to offer a mouth-watering menu at purse-pleasing prices, but since setting out on our Food Pledge journey, we’ve also begun a mission to help our customers make healthier choices. By 2025, we vow that 30% of the items on our shelves will be healthier choices. We’re well on our way to achieving this and in 2023, we smashed our target./ Over the last 12 months, we bought 30% more vegetables than our baseline reporting year of 2019 to help our customers eat their five-a-day. We’ve introduced exciting new options such as flatbreads, sandwiches, salads, fruit pots and have expanded our vegan and vegetarian options to give more choice./ We won the healthy eating award at the 2023 Sandwich and Food to Go Industry Awards for our Sweet Potato Bhaji and Rice Salad Bowl./ Of course, we’ve not forgotten the Food favourites that you all know and love. By making small changes to reduce the amount of salt, fat or sugar we’ve been able to improve their nutritional value – and your tastebuds won’t even know the difference.",
    rtl: false,
  },
  {
    name: "Packaging",
    image: shop,
    title:
      "The planet is a pretty brilliant place when you think about it – so we want to play our part in protecting it.",
    subTitle:
      "That’s why we’ve committed to using 25% less packaging in 2025 than in 2019 and over the last 12 months, we have made sure that 100% of Greggs branded printed packaging now carries a simple, recognisable recycling icon, helping our customers to get our packaging into the correct bins./ We’ll likely always need packaging to keep our products fresh and safe to eat, but we’re getting smarter and are on the way to only using packaging that is both essential and made from the most sustainable materials./ We are tackling single-use plastics, eradicated unnecessary packaging, and reduced our use of things like stretch-wrap film and plastic trays, but we will carry on looking for ways to use even less.",
    rtl: true,
  },
  {
    name: "Animal welfare",
    image: shop,
    title:
      "The welfare of animals in our supply chain is really important to us – and we only work with suppliers who align with our values.",
    subTitle:
      "To make sure we’re working with the right people, we’ve implemented the ‘Greggs Farm Animal Welfare Standards’ and we monitor how animals are treated through a number of measures, including site visits and animal welfare audits./ Our suppliers must meet and exceed our rules to ensure both the mental and physical well-being of animals reared to provide ingredients or products./ We recognise that animals are sentient beings that deserve to be treated with respect./ We ensure all animals in our supply chain are provided with fresh food and water, have shelter, are protected from pain, injury, disease or distress and have the freedom to express normal behaviours.",
    rtl: false,
  },
];

export const Promotons = [
  {
    name: "Food & Drink Offers",
  },
  {
    name: "After 4pm pizza & drink deal, from £2.60",
  },
];
