import {
  Box,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import menu from "../assets/images/menu.jpg";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TuckItems } from "../utils/CardData";
import useQueryParams from "../routes/useQueryParams";
import MenuCard from "../Components/Menu/MenuCard";
import MenuItems from "../Components/Menu/MenuItems";
import {
  useCategory,
  useCategoryMix,
  useMixAndMatch,
} from "../utils/Constants";
import { ICategory } from "../utils/types";
import { Colors, TextFormatting } from "../utils/utils";
import { useLocation } from "react-router-dom";

const Menu = () => {
  const location = useLocation();
  const pathName = location.pathname.split("/")[1];

  const cateDataDefault = useCategory();
  const cateDataMix = useCategoryMix();

  const cateData = pathName === "mix-and-match" ? cateDataMix : cateDataDefault;

  const mixAndMatch = useMixAndMatch();

  const color = Colors();

  const [value, setValue] = useState("0");

  const [categoryName, setCategoryName] = useState("");

  const { setQueryParam, getQueryParam: catName } = useQueryParams("category");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    const eventText = (event.target as HTMLButtonElement).innerText;
    const clickedTabLabel = TextFormatting(eventText);
    setValue(newValue);
    const target = event.currentTarget as HTMLElement;
    const preferredName = target.getAttribute("data-preferred-name");
    setCategoryName(preferredName as string);
    setQueryParam(clickedTabLabel);
  };

  const [search, setSearchText] = useState("");
  const [filteredItems, setFilteredItems] = useState<any>([]);

  const handleInputChange = (event: any) => {
    const inputText = event;
    setSearchText(inputText);
    setValue("0");
    const filtered = TuckItems.filter((item) => {
      return (
        item.category.toLowerCase().includes(inputText.toLowerCase()) ||
        item.children.some((child) =>
          child.title.toLowerCase().includes(inputText.toLowerCase())
        )
      );
    });
    setFilteredItems(filtered);
  };

  const clearSearch = () => {
    setSearchText("");
  };

  const [category, setCategory] = useState<ICategory[]>();

  // console.log(mixAndMatch?.attributes.products.data);

  useEffect(() => {
    setCategory(cateData);
  }, [cateData]);

  // useEffect(() => {
  //   const paramCategory = catName;
  //   const category = categoryName;

  //   if (category) {
  //     const index = TuckItems.findIndex(
  //       (item) =>
  //         item.category
  //           .toLowerCase()
  //           .replace(/\s+/g, "-")
  //           .replace(/[^\w-]/g, "") === category
  //     );
  //     const newIndex = index + 1;

  //     if (newIndex !== -1) {
  //       setValue(newIndex.toString());
  //     }
  //     scroller.scrollTo("menuCategory", {
  //       duration: 1000,
  //       delay: 50,
  //       smooth: true,
  //       offset: -80,
  //     });
  //   }
  // }, [search, catName]);

  return (
    <>
      <Stack sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            maxHeight: { xs: "80vh", lg: "450px" },
            borderBottomLeftRadius: "90px",
            borderBottomRightRadius: "90px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {mixAndMatch && pathName === "mix-and-match" ? (
            <img
              src={`${mixAndMatch.attributes.image.data[0].attributes.url}`}
              alt=""
              style={{
                objectFit: "cover",
                width: "100%", // Ensure the image takes the full width of the grid
                height: "100%",
              }}
            />
          ) : (
            <img
              src={menu}
              alt=""
              style={{
                objectFit: "cover",
                width: "100%", // Ensure the image takes the full width of the grid
                height: "100%",
              }}
            />
          )}

          <Box
            sx={{
              position: "absolute",
              zIndex: 99,
              top: { xs: "40%", lg: "20%" },
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgba(0, 85, 143, 0.8)",
                minHeight: { xs: "100px", lg: "250px" },
                width: { xs: "70%", lg: "45%" },
                borderRadius: "20px",
                border: { xs: "2px solid #FDB71A", lg: "5px solid #FDB71A" },
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: { xs: "center", lg: "center" },
                alignContent: { xs: "center", lg: "center" },
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                component="div"
                fontWeight={900}
                sx={{
                  fontFamily: "Roboto",
                  fontSize: { xs: "1.2rem", lg: "4rem" },
                  color: { xs: "#fff", lg: "rgba(0, 0, 0, 1.8)" },
                  // textShadow: `0 0 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff`,
                }}
              >
                {pathName === "mix-and-match" ? "MIX AND MATCH" : "OUR MENU"}
              </Typography>
              <Typography
                variant="h2"
                component="div"
                fontWeight={900}
                sx={{
                  color: { xs: "#fff", lg: "#FDB71A" },
                  fontFamily: "Roboto",
                  fontSize: { xs: "1.5rem", lg: "5rem" },
                }}
              >
                Find your yummy
              </Typography>
            </Box>
          </Box>
        </Box>
        <Stack sx={{ my: "50px", overflow: "hidden" }} id="menuCategory">
          <Box
            sx={{
              display: "flex",
              width: { xs: "90%", lg: "60%" },
              flexWrap: "wrap",
              alignSelf: "center",
            }}
          >
            {/*  */}
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              value={search}
              fullWidth
              sx={{ mx: "30px" }}
              onChange={(e: any) => handleInputChange(e.target.value)}
            />
            <Box sx={{ width: "100%", typography: "body1", mt: "15px" }}>
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: pathName !== "menu" ? "100%" : null,
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    variant={pathName === "menu" ? "scrollable" : undefined}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="ALL" value="0" />
                    {category ?
                      category.map((data: any) => {
                        return (
                          <Tab
                            key={data.id}
                            label={data.attributes.title}
                            value={data.id}
                            data-preferred-name={data.attributes.title}
                          />
                        );
                      }) : (
                        TuckItems.map((data: any)=>{
                          return(
                            <Tab
                            key={data.id}
                            label={data.category}
                            value={data.id}
                            data-preferred-name={data.category}
                          />
                          )
                        })
                      )}
                  </TabList>
                </Box>
                {search && (
                  <Box
                    sx={{
                      mt: "15px",
                      display: "inline-flex",
                      background: "#003B64",
                      p: "8px",
                      borderRadius: "50px",
                      color: "#fff",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">Search: {search}</Typography>
                    <Typography
                      sx={{
                        ml: "15px",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={clearSearch}
                    >
                      x
                    </Typography>
                  </Box>
                )}
                <TabPanel value={value}>
                  {search && filteredItems && filteredItems.length > 0 ? (
                    filteredItems.map((data: any) => (
                      <MenuItems
                        key={data.id}
                        productItems={data}
                        pathName={pathName}
                      />
                    ))
                  ) : search && filteredItems && filteredItems.length === 0 ? (
                    <Box sx={{ py: "40px" }}>
                      <Typography
                        variant="h6"
                        component="div"
                        fontWeight="bold"
                        sx={{
                          fontFamily: "Roboto",
                          color: color.dark,
                          textAlign: "center",
                        }}
                      >
                        OOPS... WE COULD NOT FIND ANY PRODUCTS MATCHING YOUR
                        CRITERIA: {search}
                      </Typography>
                    </Box>
                  ) : (
                    <MenuCard
                      categoryName={categoryName}
                      valueId={value}
                      category={category}
                      pathName={pathName}
                    />
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
          {/* </Element> */}
        </Stack>
        {/* <Container maxWidth="lg" sx={{ py: "60px" }}>
          <Grid
            gap={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              aligncontent: "center",
              flexWrap: "wrap",
            }}
          >
            <TaskCard />
          </Grid>
        </Container>
        <Stack
          id=""
          my="60px"
          sx={{
            px: { xs: "40px", lg: "60px" },
            display: "flex",
          }}
        >
          <Typography
            sx={{
              fontWeight: 900,
              color: color.dark,
              fontFamily: "Roboto",
              fontSize: "1.1rem",
              textAlign: "center",
              py: "20px",
            }}
          >
            App
          </Typography>
          <GiftAppComp app />
        </Stack> */}
      </Stack>
    </>
  );
};

export default Menu;
