import { Box, Popper, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import HomeLinks from "../utils/HomeLinks";
import { Link } from "react-router-dom";
import Allergen from "./Cards/Allergen";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { Colors, formatNumber } from "../utils/utils";
import { CartItem } from "../utils/types";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ButtonComp from "./Button/ButtonComp";

interface TopNavProps {
  scrolling: boolean;
  isToggle: boolean;
  menuColor?: boolean;
  clickFunction: () => void;
  showAllergin: boolean;
  toggle: () => void;
}

export const TopNav: FC<TopNavProps> = ({
  scrolling,
  isToggle,
  menuColor,
  clickFunction,
  showAllergin,
  toggle,
}) => {
  const color = Colors();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  useEffect(() => {
    const storedCartItems = JSON.parse(
      localStorage.getItem("cartItems") || "[]"
    );
    setCartItems(storedCartItems);
    calculateTotalPrice(storedCartItems);
  });

  const calculateTotalPrice = (items: any[]) => {
    const total = items.reduce((sum, item) => sum + item.price, 0);
    setTotalPrice(total);
  };

  const handleRemoveItem = (index: number) => {
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row", position: "relative" },
        }}
      >
        <Link
          to={"#"}
          onClick={() => clickFunction()}
          style={{
            marginInline: 20,
            display: "flex",
            fontWeight: "bold",
            color: scrolling || isToggle || menuColor ? color.dark : "#fff",
            fontSize: "1rem",
            cursor: "pointer",
            fontFamily: "Barlow",
            textDecoration: "none",
            marginBlock: "20px",
            textTransform: "uppercase",
            alignItems: "center",
          }}
        >
          Menus{" "}
          {showAllergin ? (
            <ArrowUpwardOutlinedIcon fontSize="inherit" sx={{ ml: "5px" }} />
          ) : (
            <ArrowDownwardOutlinedIcon fontSize="inherit" sx={{ ml: "5px" }} />
          )}
        </Link>
        {showAllergin && (
          <Box
            sx={{
              display: { xs: "flex", lg: "none" },
              flexDirection: "column",
              gap: 2,
              ml: "20px",
              width: "auto",
            }}
          >
            <Allergen
              handleAllergen={clickFunction}
              showAllergen={showAllergin}
            />
          </Box>
        )}
        {/* {HomeLinks.map((page) => (
          <Link
            to={page.path}
            key={page.text}
            style={{
              marginInline: 20,
              display: "block",
              fontWeight: 700,
              color: scrolling || isToggle || menuColor ? color.dark : "#fff",
              fontSize: "1rem",
              cursor: "pointer",
              fontFamily: "Barlow",
              textDecoration: "none",
              marginBlock: "20px",
              textTransform: "uppercase",
            }}
            onClick={showAllergin ? () => clickFunction() : () => toggle()}
          >
            {page.text}
          </Link>
        ))} */}
        <Link
          to={""}
          onClick={handleClick}
          style={{
            marginInline: 20,
            display: "flex",
            fontWeight: "bold",
            color: scrolling || isToggle || menuColor ? color.dark : "#fff",
            fontSize: "1rem",
            cursor: "pointer",
            fontFamily: "Barlow",
            textDecoration: "none",
            marginBlock: "20px",
            alignItems: "center",
            position: "relative",
          }}
        >
          <ShoppingBagOutlinedIcon />
          <Box
            sx={{
              position: "absolute",
              top: -5,
              left: -10,
              background: color.dark,
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "10px", color: "#fff" }}>
              {cartItems && cartItems.length}
            </Typography>
          </Box>
        </Link>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          sx={{ zIndex: 99, position: "static", top: 0 }}
        >
          <Box
            sx={{
              p: 1,
              bgcolor: "background.paper",
              mt: "20px",
              width: "250px",
              minHeight: "230px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {cartItems &&
              cartItems.map((data: any, index: number) => (
                <Box
                  key={index}
                  sx={{
                    mb: "5px",
                    pb: "5px",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Link
                    to={`/${data.pathName}/product/${data.slug}`}
                    style={{
                      textDecoration: "none",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #F7F7F7",
                    }}
                  >
                    <Box
                      sx={{ width: "45px", height: "45px", overflow: "hidden" }}
                    >
                      <img
                        src={data?.imageSrc}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <Box sx={{ ml: "10px", width: "70%" }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "#5D5D5E",
                          fontSize: "12px",
                        }}
                      >
                        {data.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "#5D5D5E",
                          textAlign: "justify",
                          fontSize: "10px",
                        }}
                      >
                        {formatNumber(data.price ?? 0)}
                      </Typography>
                    </Box>
                  </Link>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleRemoveItem(index)}
                  >
                    <CloseOutlinedIcon sx={{ fontSize: "1.0rem" }} />
                  </Box>
                </Box>
              ))}
            <Box sx={{ flexGrow: 1 }} />
            <Box
              id="checkout"
              sx={{
                mb: "auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontWeight: 600, color: "#5D5D5E", fontSize: "12px" }}
              >
                Total: {formatNumber(totalPrice)}
              </Typography>
              <ButtonComp variant="text" text="Checkout" href="/checkout" />
            </Box>
          </Box>
        </Popper>
      </Box>
    </>
  );
};
