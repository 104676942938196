import { Stack, Box, Typography, Container } from "@mui/material";
import React from "react";
import shop from "../assets/images/aboutus.jpg";
import { Colors } from "../utils/utils";
import useStyles from "../utils/useStyles";

const AboutUs = () => {
  const color = Colors();
  const style = useStyles();
  return (
    <>
      <Stack sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            maxHeight: { xs: "80vh", lg: "550px" },
            borderBottomLeftRadius: "90px",
            borderBottomRightRadius: "90px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            src={shop}
            alt=""
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              filter: "blur(2px)",
            }}
          />
        </Box>
        <Stack sx={{ my: "60px", overflow: "hidden" }} id="menuCategory">
          <Container maxWidth="md" sx={{}}>
            <Typography
              variant="h2"
              component="div"
              fontWeight={900}
              sx={{
                color: color.dark,
                fontFamily: "Roboto",
                fontSize: { xs: "3.5rem", lg: "2.5rem" },
                textAlign: "center",
              }}
            >
              About Us
            </Typography>
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: color.dark,
                  my: "20px",
                  textAlign: "justify",
                  fontFamily: "Roboto",
                }}
              >
                Our bakery products are made from the finest of ingredients
                blended together to give you an unforgettable experience.
              </Typography>
              <Typography sx={style.text}>
                We strive to to giving you value for your money. Our products
                ranging from bread, pastries, snacks are a delight to your taste
                bud and we are committed to making sure every product you buy
                from us is worth the spending.
              </Typography>
              <Typography sx={style.text}>
                We are also introducing an innovative way of ordering your
                bakery products. The mix and bake section allows you to select
                from our ingredients and create a bespoke order tailored for
                yourself. You select the ingredients, we bake and send to you.
              </Typography>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "20px",
                  flexDirection: { xs: "column", lg: "row" },
                }}
              >
                <Box
                  sx={{
                    height: "335px",
                    width: "400px",
                    background: "gray",
                    borderRadius: "40px",
                  }}
                ></Box>
                <Box
                  sx={{
                    height: "335px",
                    width: "400px",
                    background: "gray",
                    borderRadius: "40px",
                  }}
                ></Box>
              </Box> */}           
            </Box>
          </Container>
        </Stack>
      </Stack>
    </>
  );
};

export default AboutUs;
