import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  Stack,
  Typography,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import Hero from "../Components/Hero/Hero";
import { Colors } from "../utils/utils";
import contactus from "../assets/images/contact-us.jpg";
import useStyles from "../utils/useStyles";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "../Components/Form/InputText";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});

const ContactUs = () => {
  const color = Colors();
  const style = useStyles();
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(Boolean(false));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      product: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await setIsLoading(!isLoading);
      try {
        const data = { data: values };
        await axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}/api/contact-uses`, data)
          .then(async (res) => {
            if (res.status === 200) {
              handleOpen();
              formik.resetForm();
              await setIsLoading(false);
              setChecked(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error("Error creating document:", error);
      }
    },
  });
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
          variant="filled"
        >
          Message submitted successfully
        </Alert>
      </Snackbar>
      <Stack sx={{ overflow: "hidden", background: "#fff" }}>
        <Hero
          fixed
          title="Reach out to us"
          subtitle="Don’t be shy - we’d love to hear from you"
          backgroundColor="#000033"
          image={contactus}
          titleMainColor="#FDB71A"
          heroHeight="500px"
        />
        <Container maxWidth="md">
          <Box sx={{ py: "20px" }}>
            <Typography sx={style.contactSpeak}>
              Got an order to make? or question(s) to ask?
            </Typography>
          </Box>
        </Container>
        <Box sx={{ background: "#F9F9F9", py: "80px" }}>
          <Box sx={{ width: "40%", m: "auto" }}>
            <Typography
              variant="h2"
              component="div"
              fontWeight={900}
              sx={{
                color: color.dark,
                fontFamily: "Roboto",
                fontSize: { xs: "3.5rem", lg: "2.5rem" },
              }}
            >
              We're all ears
            </Typography>
            <Typography sx={style.text}>
              Please leave your contact information:
            </Typography>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  my: "15px",
                }}
              >
                <Box sx={{ width: "45%" }}>
                  <FormLabel sx={style.textSub}>First Name</FormLabel>
                  <InputText
                    size="medium"
                    label="firstName"
                    placeholder="Enter first name"
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    sx={{ width: "100%", fontWeight: "bold" }}
                  />
                </Box>
                <Box sx={{ width: "45%" }}>
                  <FormLabel sx={style.textSub}>Last Name</FormLabel>
                  <InputText
                    size="medium"
                    label="lastName"
                    placeholder="Enter last name"
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    sx={{ width: "100%", fontWeight: "bold" }}
                  />
                </Box>
              </Box>
              <Box sx={{ my: "20px" }}>
                <FormLabel sx={style.textSub}>Email Address</FormLabel>
                <InputText
                  size="medium"
                  type="email"
                  label="email"
                  placeholder="example@gmail.co.uk"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  sx={{ width: "100%", fontWeight: "bold" }}
                />
              </Box>
              <Box sx={{ my: "20px" }}>
                <FormLabel sx={style.textSub}>
                  Which product are you contacting us about?
                </FormLabel>
                <InputText
                  size="medium"
                  id="product"
                  label="product"
                  name="product"
                  type="text"
                  value={formik.values.product}
                  onChange={formik.handleChange}
                  placeholder="Enter product name"
                  sx={{ width: "100%", fontWeight: "bold" }}
                />
              </Box>
              <Box sx={{ mt: "20px" }}>
                <InputText
                  size="medium"
                  id="message"
                  label="Enter your messages"
                  multiline
                  rows={7}
                  variant="outlined"
                  fullWidth
                  name="message"
                  type="text"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  sx={{ mb: "30px" }}
                />
              </Box>
              <Box sx={{ p: "0px" }}>
                <FormControlLabel
                  control={
                    <Checkbox value={checked} onChange={handleChecked} />
                  }
                  label={
                    <Typography sx={style.text}>
                      I agree to the <Link to="">Privacy Policy</Link>
                    </Typography>
                  }
                />
              </Box>
              {/* /api/contact-uses */}
              {isLoading ? (
                <LoadingButton loading variant="outlined" fullWidth>
                  Submit
                </LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={!checked}
                >
                  Send message
                </Button>
              )}
            </form>
          </Box>
        </Box>
        <Box sx={{ minHeight: "420px" }}>
          <Box sx={{ px: "60px", mt: "50px" }}>
            <Typography
              variant="h2"
              component="div"
              fontWeight={900}
              sx={{
                color: color.dark,
                fontFamily: "Roboto",
                fontSize: { xs: "3.5rem", lg: "2.5rem" },
              }}
            >
              Speak to us
            </Typography>

            <Grid
              container
              sx={{
                display: "flex",
                // justifyContent: "space-between",
                flexWrap: "wrap",
                alignContent: "center",
                mt: "40px",
              }}
            >
              <Grid item xs={12} md={4} lg={3}>
                <Box sx={{ mt: "15px", color: color.dark }}>
                  <Typography sx={style.contactSpeak}>
                    CONTACT DETAILS
                  </Typography>
                  <Box sx={{ display: "flex", mt: "15px", gap: 2 }}>
                    <PhoneOutlinedIcon />
                    <Typography
                      sx={{ fontFamily: "Roboto", fontWeight: "bold" }}
                    >
                      07475479691
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", mt: "15px", gap: 2 }}>
                    <BsWhatsapp size={20} />
                    <Typography
                      sx={{ fontFamily: "Roboto", fontWeight: "bold" }}
                    >
                      07395640790
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xs={12} md={4} lg={3}>
                <Box sx={{ mt: "15px", color: color.dark }}>
                  <Typography sx={style.contactSpeak}>RECRUITMENT</Typography>
                  <Box sx={{ display: "flex", mt: "15px", gap: 2 }}>
                    <LanguageIcon />
                    <Typography
                      sx={{ fontFamily: "Roboto", fontWeight: "bold" }}
                    >
                      careers.name.domain
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", mt: "15px", gap: 2 }}>
                    <PhoneOutlinedIcon />
                    <Typography
                      sx={{ fontFamily: "Roboto", fontWeight: "bold" }}
                    >
                      01945834789
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <Box sx={{ mt: "15px", color: color.dark }}>
                  <Typography sx={style.contactSpeak}>FOUNDATION</Typography>
                  <Box sx={{ display: "flex", mt: "15px", gap: 2 }}>
                    <LanguageIcon />
                    <Typography
                      sx={{ fontFamily: "Roboto", fontWeight: "bold" }}
                    >
                      name.foundation.domain
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", mt: "15px", gap: 2 }}>
                    <PhoneOutlinedIcon />
                    <Typography
                      sx={{ fontFamily: "Roboto", fontWeight: "bold" }}
                    >
                      01945834789
                    </Typography>
                  </Box>
                </Box>
              </Grid> */}

              <Grid item xs={12} md={4} lg={3}>
                <Box sx={{ mt: "15px", color: color.dark }}>
                  <Typography sx={style.contactSpeak}>ADDRESS</Typography>
                  <Box sx={{ display: "flex", mt: "15px", gap: 2 }}>
                    <LocationOnIcon />
                    <Typography
                      sx={{ fontWeight: 600, color: "#5D5D5E", width: "60%" }}
                    >
                      103 Shields Road Newcastle upon Tyne NE6 1DN
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default ContactUs;
