import { Button } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";

interface ButtonCompProps {
  variant: "text" | "contained" | "outlined";
  background?: string;
  text?: string;
  color?: string;
  bordercolor?: string;
  href?: string;
  icon?: JSX.Element
  [x: string]: any;
}

const ButtonComp: FC<ButtonCompProps> = ({
  variant,
  background,
  text,
  color,
  bordercolor,
  href,
  icon,
  ...props
}) => {
  return (
    <>
      <Link to={href || ""}>
        <Button
          variant={variant}
          sx={{
            py: {lg: "15px", xs: '10px'},
            px:{ lg: "30px"},
            background: background,
            color: color,
            fontWeight: "bold",
            fontFamily: "Roboto",
            borderColor: bordercolor,
          }}
         
          disableElevation
          {...props}
        >
          {text} {icon}
        </Button>
      </Link>
    </>
  );
};

export default ButtonComp;
